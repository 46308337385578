// import { ethers } from 'ethers'
import { getNow } from 'utils/getNow'
import { Web3Provider } from '@ethersproject/providers'

export type TTimeSigner = {
  currentTime?: string | number
  signature?: string
  address?: string
}

export const signUnixTime = async (
  library: Web3Provider,
): Promise<{
  currentTime: string
  signature: string
  address: string
}> => {
  const currentTime = getNow().toString()
  try {
    if (!window.ethereum) throw new Error('No crypto wallet found. Please install it.')

    await window.ethereum.send('eth_requestAccounts')
    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = library.getSigner()
    const signature = await signer.signMessage(currentTime)
    const address = await signer.getAddress()

    return {
      currentTime,
      signature,
      address,
    }
  } catch (err: any) {
    // console.log(err.message)
    return null
  }
}

export const getSocialLabel = (social: string) => {
  let label: string
  switch (social) {
    case 'twitter':
      label = 'Follow'
      break
    case 'discord':
      label = 'Join'
      break
    case 'telegram':
      label = 'Join'
      break
    default:
      label = 'Follow'
  }
  return label
}
