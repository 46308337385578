import { ChainId, Currency, CurrencyAmount, Native, Token, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import { BTC_CHAIN_ID, CHAIN_ID } from 'config/constants/networks'
import { TokenForeignTypeEnums, MethodCrossSwap } from 'contexts/DefiContext'
import { CrossChainTransaction } from 'state/cross-swap-transactions/types'
import { Field } from 'state/swap/actions'
import { isBitcoinChain, isZetaChain, isTestnet } from 'utils/network'
import { tryWrappedCurrencyAmount } from 'utils/wrappedCurrencyCross'

import bridgeTokenMainnet from 'config/constants/tokenLists/brigde-mainnet.json'
import bridgeTokenTesnet from 'config/constants/tokenLists/brigde-testnet.json'
import crossTokenMainnet from 'config/constants/tokenLists/cross-mainnet.json'
import crossTokenTestnet from 'config/constants/tokenLists/cross-testnet.json'

export interface CrossSwapState {
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined

    readonly chainId: ChainId
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined

    readonly chainId: ChainId
  }

  readonly transactions: CrossChainTransaction[]
}
export interface ICrossSwapTrade {
  inputCurrency: Currency | Token
  inputAmount: string

  outputCurrency: Currency | Token
  outputAmount: string

  inputChainId: ChainId
  outputChainId: ChainId

  slippage: number

  recipient: string
}
const compareAddress = (addressA, addressB) => addressA?.toLowerCase() === addressB?.toLowerCase()

export const getForeignCoins = () => {
  return (isTestnet(parseInt(CHAIN_ID, 10)) ? crossTokenTestnet : crossTokenMainnet).foreignCoins.map((token) => {
    return {
      name: token.name,
      symbol: token.symbol,
      decimals: token.decimals,
      foreignChainId: Number(token.foreign_chain_id),
      address: token.asset,
      zrc20Address: token.zrc20_contract_address,
      type: token.coin_type as TokenForeignTypeEnums,
    }
  })
}

export const getNativeTokenEVMAtZeta = (chainId: ChainId) => {
  const foreignCoins = getForeignCoins()
  const tokenNativeZrc20 = foreignCoins.find(
    (token) => token.foreignChainId === chainId && token.type === TokenForeignTypeEnums.Gas,
  )
  if (tokenNativeZrc20) {
    return new Token(
      parseInt(CHAIN_ID, 10),
      tokenNativeZrc20.zrc20Address,
      tokenNativeZrc20.decimals,
      tokenNativeZrc20.symbol,
      tokenNativeZrc20.name,
    )
  }

  return null
}

export const getDefaultBridgeToken = (chainId: ChainId) => {
  return (isTestnet(chainId) ? bridgeTokenTesnet : bridgeTokenMainnet).tokens
    .map(
      (token) =>
        chainId === token.chainId && new Token(token.chainId, token.address, token.decimals, token.symbol, token.name),
    )
    .filter((item) => item)
}

export const getAddressZrc20ByChainId = (token: Token | Currency, chainId: ChainId): string => {
  if (isZetaChain(chainId) && Native.onChain(chainId) === token) {
    return Native.onChain(chainId).wrapped.address
  }

  return getForeignCoins().find((t) => {
    return (
      t.foreignChainId === chainId &&
      (isNative(token) && t.type === TokenForeignTypeEnums.Gas
        ? true
        : compareAddress(t.address, (token as Token)?.address))
    )
  })?.zrc20Address
}

export const getAddressZrc20ByTokenAmount = (tokenAmount: CurrencyAmount | TokenAmount, chainId: ChainId): string => {
  return getAddressZrc20ByChainId(tokenAmount.currency || (tokenAmount as TokenAmount)?.token, chainId)
}

export const isNative = (currency: any) => {
  return (currency as unknown as Native)?.isNative || !currency?.address || !(currency instanceof Token)
}

export class CrossSwapTrade {
  public readonly inputIsNative: boolean

  public readonly outputIsNative: boolean

  public readonly inputTokenAmount: TokenAmount

  public readonly outputTokenAmount: TokenAmount

  public readonly originalInputCurrency: Token | Currency

  public readonly originalOutputCurrency: Token | Currency

  private readonly _methodSwap: MethodCrossSwap

  // because if user select native, i can get native of that token
  public readonly inputChainId: ChainId

  public readonly outputChainId: ChainId

  public readonly recipient: string

  public readonly slippage: number

  constructor(args: ICrossSwapTrade) {
    this.slippage = args.slippage
    this.recipient = args.recipient

    this.inputChainId = args.inputChainId
    this.outputChainId = args.outputChainId
    this.inputIsNative = isNative(args.inputCurrency)
    this.outputIsNative = isNative(args.outputCurrency)

    this.originalInputCurrency = args.inputCurrency
    this.originalOutputCurrency = args.outputCurrency

    this.inputTokenAmount = tryWrappedCurrencyAmount(args.inputCurrency, args.inputAmount, this.inputChainId)
    this.outputTokenAmount = tryWrappedCurrencyAmount(args.outputCurrency, args.outputAmount, this.outputChainId)

    this._methodSwap = getMethodCrossChain(
      this.inputIsNative ? this.originalInputCurrency : this.inputTokenAmount?.token,
      this.outputIsNative ? this.originalOutputCurrency : this.outputTokenAmount?.token,
      this.inputChainId,
      this.outputChainId,
    )
  }

  public get methodSwap(): MethodCrossSwap {
    return this._methodSwap
  }
}

export const validateNativeSymbol = (native: string, symbol: string) => {
  const symbolNative = native.charAt(0).toUpperCase() === 'T' ? native.replace('t', '') : native
  return new RegExp(`^t?${symbolNative?.toUpperCase()}$`, 'i').test(symbol.toUpperCase())
}

export const validateSameToken = ({
  inputToken,
  outputToken,
  inputChain,
  outputChain,
}: {
  inputToken: Token | Currency | Native
  outputToken: Token | Currency | Native
  inputChain: ChainId
  outputChain: ChainId
}) => {
  const isInputNative = isNative(inputToken)
  const isOutputNative = isNative(outputToken)
  const crossTokens = getForeignCoins()

  const inputAddress = (inputToken as Token)?.address?.toLowerCase()
  const outputAddress = (outputToken as Token)?.address?.toLowerCase()

  // ZRC20 ZETA -> Native Other chain
  if (isZetaChain(inputChain) && !isInputNative && isOutputNative && inputAddress) {
    return !!crossTokens.find((token) => {
      return (
        token.foreignChainId === outputChain &&
        compareAddress(inputAddress, token.zrc20Address) &&
        token.type === TokenForeignTypeEnums.Gas
      )
    })
  }

  // Native Other chain -> ZRC20 ZETA
  if (isZetaChain(outputChain) && isInputNative && !isOutputNative && outputAddress) {
    return !!crossTokens.find((token) => {
      return (
        token.foreignChainId === inputChain &&
        compareAddress(outputAddress, token.zrc20Address) &&
        token.type === TokenForeignTypeEnums.Gas
      )
    })
  }

  // ZRC20 ZETA -> ERC20 Other chain
  if (isZetaChain(inputChain) && !isInputNative && !isOutputNative && inputAddress && outputAddress) {
    return !!crossTokens.find((token) => {
      return (
        token.foreignChainId === outputChain &&
        compareAddress(inputAddress, token.zrc20Address) &&
        compareAddress(outputAddress, token.address)
      )
    })
  }

  // ERC20 Other chain -> ZRC20 ZETA
  if (isZetaChain(outputChain) && !isInputNative && !isOutputNative && inputAddress && outputAddress) {
    return !!crossTokens.find((token) => {
      return (
        token.foreignChainId === inputChain &&
        compareAddress(inputAddress, token.address) &&
        compareAddress(outputAddress, token.zrc20Address)
      )
    })
  }

  return false
}

export const isBTC = (inputToken: Token | Currency, inputChain: ChainId) => {
  if (isBitcoinChain(inputChain)) return true

  const crossTokens = getForeignCoins()
  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.Gas &&
      token.foreignChainId === BTC_CHAIN_ID &&
      compareAddress((inputToken as Token)?.address, token.zrc20Address)
    )
  })
}

const validateBridgeToken = (inputToken: Token, outputToken: Token, inputChain: ChainId, outputChain: ChainId) => {
  const crossTokens = getForeignCoins()

  return !!crossTokens.find((token) => {
    return (
      token.foreignChainId === inputChain &&
      compareAddress(inputToken?.address, token.address) &&
      compareAddress(outputToken?.address, token.zrc20Address)
    )
  })
}

export const isErc20 = (inputToken: Token | Currency, inputChain: ChainId) => {
  if (isZetaChain(inputChain) && isNative(inputToken)) return false

  const crossTokens = getForeignCoins()

  return !!crossTokens.find((token) => {
    return token.type === TokenForeignTypeEnums.ERC20 && compareAddress((inputToken as Token)?.address, token.address)
  })
}

export const isStableZrc20 = (inputToken: Token | Currency, inputChain: ChainId) => {
  if (!isZetaChain(inputChain) || isNative(inputToken)) return false

  const crossTokens = getForeignCoins()

  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.ERC20 && compareAddress((inputToken as Token)?.address, token.zrc20Address)
    )
  })
}

export const isNativeZrc20 = (inputToken: Token | Currency, inputChain: ChainId) => {
  if (!isZetaChain(inputChain) || isNative(inputToken)) return false

  const crossTokens = getForeignCoins()

  return !!crossTokens.find((token) => {
    return (
      token.type === TokenForeignTypeEnums.Gas && compareAddress((inputToken as Token)?.address, token.zrc20Address)
    )
  })
}

export const getMethodCrossChain = (
  inputToken: Currency | Token,
  outputToken: Currency | Token,
  inputChain: ChainId,
  outputChain: ChainId,
): MethodCrossSwap => {
  if (!inputToken || !outputToken) return null

  const isInputNative = isNative(inputToken)
  const isOutputNative = isNative(outputToken)

  const inputSymbol = inputToken.symbol?.toUpperCase() || ''
  const outputSymbol = outputToken.symbol?.toUpperCase() || ''

  const nativeCurrency = Native.onChain(parseInt(CHAIN_ID, 10))
  const wrapNativeCurrency = Native.onChain(parseInt(CHAIN_ID, 10)).wrapped

  const nativeSymbol = nativeCurrency.symbol.toUpperCase()
  const wrapNativeSymbol = wrapNativeCurrency.symbol.toUpperCase()

  const fromZETAorWZETA = [nativeSymbol, wrapNativeSymbol].includes(inputSymbol)

  const fromZetaChain = isZetaChain(inputChain)
  const fromBTC = isBTC(inputToken, inputChain)
  const toBTC = isBTC(outputToken, outputChain)

  const toZETAorWZETA = [nativeSymbol, wrapNativeSymbol].includes(outputSymbol)
  const toZetaChain = isZetaChain(outputChain)

  const fromErc20 = isErc20(inputToken, inputChain)
  const toErc20 = isErc20(outputToken, outputChain)

  const fromStableZrc20 = isStableZrc20(inputToken, inputChain)
  const toStableZrc20 = isStableZrc20(outputToken, outputChain)

  const fromNativeZrc20 = isNativeZrc20(inputToken, inputChain)
  const toNativeZrc20 = isNativeZrc20(outputToken, outputChain)

  const fromBTCtoBTC = fromBTC && toBTC
  const fromBTCOrtoBTC = fromBTC || toBTC

  const toBitcoin = isBitcoinChain(outputChain)
  const toNative = Native.onChain(outputChain).equals(outputToken as mCurrency)

  const sameToken = validateSameToken({
    inputChain,
    inputToken,
    outputChain,
    outputToken,
  })

  const sameChain = inputChain === outputChain
  const fromToZetaChain = fromZetaChain || toZetaChain
  const fromToZETAorWZETA = fromZETAorWZETA || toZETAorWZETA

  const isFromErc20ToZrc20 = fromErc20 && toStableZrc20
  const isFromZrc20ToErc20 = fromNativeZrc20 && toErc20
  const isFromStableZrc20ToErc20 = fromStableZrc20 && toErc20

  const isBridgeERC20 =
    isFromErc20ToZrc20 && validateBridgeToken(inputToken as Token, outputToken as Token, inputChain, outputChain)

  const isBridgeZRC20 =
    isFromStableZrc20ToErc20 && validateBridgeToken(outputToken as Token, inputToken as Token, outputChain, inputChain)

  const isFromNativeToStableZrc20 = isInputNative && toStableZrc20 && !toBTC
  const isFromNativeToErc20 = isInputNative && toErc20 && !toBTC

  const isFromErc20ToAnotherZrc20 = isFromErc20ToZrc20 && !isBridgeERC20

  const isFromStableZrc20ToAnotherErc20 = isFromStableZrc20ToErc20 && !isBridgeZRC20
  const isFromNativeZrc20ToAnotherErc20 = isFromZrc20ToErc20 && !isBridgeZRC20

  const fromOrToIsNative = isInputNative || isOutputNative

  // USDT (ETH) -> USDT (ZETA), // USDC (BSC) -> USDC(ZETA)
  if (sameToken && !fromZetaChain && toZetaChain && isBridgeERC20 && !fromOrToIsNative && !fromBTC)
    return MethodCrossSwap.depositERC20

  // USDT (ZETA) -> USDT (ETH), // USDC (ZETA) -> USDC(BSC)
  // if (sameToken && fromZetaChain && !toZetaChain && isBridgeZRC20 && !fromOrToIsNative && !fromBTC)
  //   return MethodCrossSwap.withdrawERC20

  // ETH (ETH) -> ETH(ZETA), // BNB (BSC) -> BNB(ZETA)
  // if (sameToken && !fromZetaChain && toZetaChain && !isBridgeERC20 && fromOrToIsNative && !fromBTC)
  //   return MethodCrossSwap.depositNative

  // ETH (ZETA) -> ETH(ETH), // BNB (ZETA) -> BNB(BSC)
  // if (sameToken && fromZetaChain && !toZetaChain && !fromBTC) return MethodCrossSwap.withdrawZRC20

  // BTC (BITCOIN) -> BTC(ZETA)
  // if (fromBTCtoBTC && !fromZetaChain && toZetaChain && !toZETAorWZETA) return MethodCrossSwap.depositBTC

  // BTC(ZETA) -> BTC (BITCOIN)
  // if (fromZetaChain && !fromZETAorWZETA && !toZetaChain && toBitcoin) return MethodCrossSwap.withdrawBTC

  // BTC (BITCOIN) -> BNB(BSC) (Other Chain, token NATIVE)
  if (fromBTC && !toBitcoin && !fromToZetaChain && !toErc20 && !toStableZrc20 && !toZETAorWZETA)
    return MethodCrossSwap.crossChainSwapBTC

  // BTC (BITCOIN) -> BNB(ZETA) (to Zeta, token ZRC20)
  if (fromBTC && !fromZetaChain && toZetaChain && !toErc20 && !toStableZrc20 && !toZETAorWZETA && !fromBTCtoBTC)
    return MethodCrossSwap.crossChainSwapBTCTransfer

  // BTC (BITCOIN) -> ZETA(ZETA) (to Zeta, token NATIVE)
  if (fromBTC && !fromZetaChain && toZetaChain && (toZETAorWZETA || toBTC))
    return MethodCrossSwap.crossChainSwapBTCTransferNative

  /*
    ETH (ZETA) -> BNB(BSC)
    ETH (ZETA) -> MATIC(POLYGON)
    From ZRC20 ZETA to another chain (no match between ZRC20 And another chain NATIVE)
  */
  if (
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !isFromStableZrc20ToAnotherErc20 &&
    !isFromNativeZrc20ToAnotherErc20 &&
    !toZetaChain &&
    !fromToZETAorWZETA &&
    !sameChain &&
    fromZetaChain
  )
    return MethodCrossSwap.crossChainZeta

  /*
    BNB (BSC) -> ETH(ETH),
    ETH (ETH) -> BNB(BSC)
    BNB (BSC) -> MATIC(POLYGON)
    BNB (BSC) -> BTC(BITCOIN),
    -> from NATIVE to another chain, but not to ZETA (no match between ZRC20 And another chain NATIVE)
  */
  if (
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !toZetaChain &&
    !fromToZETAorWZETA &&
    !sameChain &&
    isInputNative
  )
    return MethodCrossSwap.crossChainSwap

  /*
    ETH (ETH) -> BNB(ZETA)
    ETH (ETH) -> MATIC(ZETA)

    -> from NATIVE to ZETA Chain (no match between ZRC20 And another chain NATIVE)
  */

  if (
    isInputNative &&
    toNativeZrc20 &&
    !isFromNativeToStableZrc20 &&
    !isFromNativeToErc20 &&
    !isFromErc20ToAnotherZrc20 &&
    !fromZetaChain &&
    !fromToZETAorWZETA &&
    !fromBTCtoBTC &&
    toZetaChain
  )
    return MethodCrossSwap.crossChainSwapTransfer

  /*
    ETH (ETH) -> ZETA(ZETA)
    BNB (BSC) -> ZETA(ZETA)
    -> from NATIVE to NATIVE (no match between ZRC20 And another chain NATIVE)
  */
  if (!fromZetaChain && !fromErc20 && !fromBTCtoBTC && toZetaChain && toZETAorWZETA)
    return MethodCrossSwap.crossChainSwapNativeTransfer

  /*
   ZETA(ZETA) ->  ETH (ETH) 
   ZETA(ZETA) ->  BNB (BSC) 
   ZETA(ZETA) ->  BTC (BITCOIN) 
   -> from NATIVE to NATIVE (no match between ZRC20 And another chain NATIVE)
  */
  if (fromZetaChain && fromZETAorWZETA && toNative) return MethodCrossSwap.crossChainSwapNative

  return null
}
