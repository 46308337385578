import { ChainId } from '@abstra-dex/sdk'
import { CHAIN_ID } from 'config/constants/networks'

const endpoints = {
  [ChainId.MAINNET]: {
    'cosmos-http': 'https://zetachain.blockpi.network/lcd/v1/public',
  },
  [ChainId.TESTNET]: {
    'cosmos-http': 'https://zetachain-athens.blockpi.network/lcd/v1/public',
  },
  [ChainId.BTC_MAINNET]: {
    esplora: 'https://blockstream.info/api',
  },
  [ChainId.BTC_TESTNET]: {
    esplora: 'https://blockstream.info/testnet/api',
  },
}

export const getCosmosHttp = () => {
  return endpoints[parseInt(CHAIN_ID, 10)]['cosmos-http']
}

export const getEsplora = (chainId: ChainId = parseInt(CHAIN_ID, 10)) => {
  return (endpoints[chainId] as any)?.esplora
}
