import { createReducer } from '@reduxjs/toolkit'
import { addStateTransactions, removeStateTransactions, updateStateTransaction } from './actions'
import { CrossChainTransaction } from './types'

interface CrossSwapTransactionState {
  transactions: {
    [txn: string]: CrossChainTransaction[]
  }
}
const initialState: CrossSwapTransactionState = {
  transactions: {},
}

export default createReducer<CrossSwapTransactionState>(initialState, (builder) =>
  builder
    .addCase(addStateTransactions, (state, { payload }) => {
      if ((state.transactions as any)?.length >= 0) {
        state.transactions = {}
      }

      const txns = state.transactions[payload.account] || []
      const transactions = [...txns].filter((txn) => txn.hash !== payload.txn.hash)
      state.transactions[payload.account] = [payload.txn, ...transactions]
      return state
    })
    .addCase(removeStateTransactions, (state, { payload }) => {
      if ((state.transactions as any)?.length >= 0) {
        state.transactions = {}
      }

      const txns = state.transactions[payload.account] || []
      const transactions = [...txns].filter((txn) => txn.hash !== payload.txn.hash)
      state.transactions[payload.account] = [...transactions]
      return state
    })
    .addCase(updateStateTransaction, (state, { payload }) => {
      if ((state.transactions as any)?.length >= 0) {
        state.transactions = {}
      }

      const txns = state.transactions[payload.account] || []

      const transactionIndex = [...txns].findIndex((txn) => txn.hash === payload.txn.hash)
      state.transactions[payload.account][transactionIndex] = payload.txn
      return state
    }),
)
