import styled, { keyframes } from 'styled-components'

const LoadingCircle = ({ ...props }) => {
  return (
    <Loading {...props}>
      <img src="/images/loading-icon.svg" alt="loading-icon" />
    </Loading>
  )
}

export default LoadingCircle

export const loadingAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  `

const Loading = styled.div`
  height: 21px;
  display: flex;

  img {
    animation: ${loadingAnimation} 2.5s linear infinite;
  }
  /* animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; */
`
