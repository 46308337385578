import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import Flex from '../../../components/Box/Flex'
// import { LogoIcon, LogoWithTextIcon } from '../../../components/Svg'
import { MenuContext } from '../context'

interface Props {
  href: string
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`

const StyledLink = styled('a')`
  display: flex;
  align-items: center;
  padding: 24px;

  .mobile-icon {
    width: 32px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    width: 500px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const StyledLogo = styled.div`
  display: none;
  width: fit-content;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.absWhiteColor};

  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  img {
    display: flex;
    flex-shrink: 0;
    max-width: 36px;
    aspect-ratio: 1/1;
    height: auto;
    margin-right: 8px;
  }
`

const Logo: React.FC<Props> = ({ href }) => {
  const { linkComponent } = useContext(MenuContext)
  const isAbsoluteUrl = href.startsWith('http')

  const innerLogo = (
    <StyledLogo>
      {/* <img alt="logo mobile" className="mobile-icon" src="/images/abstradex/svgs/logo.svg" /> */}
      <img alt="logo desktop" className="desktop-icon" src="/images/abstradex/svgs/logo.svg" />
      AbstraDEX
    </StyledLogo>
  )

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink href="https://abstradex.xyz/" as="a" aria-label="AbstraDEX home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink href="https://abstradex.xyz/" as={linkComponent} aria-label="AbstraDEX home page">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
