/* eslint-disable no-empty */
/* eslint-disable camelcase */
import { ChainId, Currency, JSBI, MCurrencyAmount, Native, Token, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import { BASE_RPC_SCAN_URLS } from 'config'
import { ERC20_ABI } from 'config/abi/erc20'
import { TokenForeignTypeEnums, ZERO_ADDRESS } from 'config/constants/cross-chain'
import { CHAIN_ID } from 'config/constants/networks'
import { ethers } from 'ethers'
import { isBitcoinChain, isTestnet } from 'utils/network'
import { getForeignCoins } from 'state/cross-swap/types'
import { getCosmosHttp, getEsplora } from './endpoints'

// export const getForeignCoins = async () => {
//   const api = getCosmosHttp()
//   const endpoint = `${api}/zeta-chain/fungible/foreign_coins`
//   const response = await fetch(endpoint)
//   const data = await response.json()
//   return data.foreignCoins
// }

export const getSupportedChains = async () => {
  const api = getCosmosHttp()
  const endpoint = `${api}/zeta-chain/observer/supportedChains`
  const response = await fetch(endpoint)
  const data = await response.json()
  return data.chains
}

export const parseBalanceZeta = (data: any): TokenAmount | MCurrencyAmount<mCurrency> => {
  const amount = JSBI.BigInt(Math.floor(Number(data.balance) * 10 ** data.decimals))

  const token = new Token(
    parseInt(data.chain_id, 10),
    data.zrc20 || ZERO_ADDRESS,
    data.decimals,
    data.symbol,
    data.coin_type,
  )

  return data.coin_type === TokenForeignTypeEnums.Gas
    ? MCurrencyAmount.fromRawAmount(Native.onChain(parseInt(data.chain_id, 10)), amount)
    : new TokenAmount(token, amount)
}

export const getBitcoinBalance = async (
  bitcoinAddress: string,
  chainId: ChainId,
): Promise<MCurrencyAmount<mCurrency>> => {
  const endpoint = getEsplora(isTestnet(chainId) ? ChainId.BTC_TESTNET : ChainId.BTC_MAINNET)

  const data = await fetch(`${endpoint}/address/${bitcoinAddress}`)
    .then((response) => {
      return response.json()
    })
    .catch((e) => e)

  if (data?.address) {
    const { funded_txo_sum: fundedTxoSum, spent_txo_sum: spentTxoSum } = data.chain_stats
    JSBI.BigInt(fundedTxoSum - spentTxoSum)
    return MCurrencyAmount.fromRawAmount(Native.onChain(chainId), JSBI.BigInt(fundedTxoSum - spentTxoSum))
  }
  return null
}

export const getBalances = async (evmAddress: any, btcAddress = null) => {
  const tokens: (Native | mCurrency | Token)[] = []

  const foreignCoins = getForeignCoins()
  const defaultChainId = parseInt(CHAIN_ID, 10)

  const testnet = defaultChainId === ChainId.TESTNET

  foreignCoins.forEach((token: any) => {
    const foreignChainId = parseInt(token.foreignChainId, 10)

    if (testnet && !isTestnet(foreignChainId)) return

    if (token.type === TokenForeignTypeEnums.Gas) {
      const native = Native.onChain(foreignChainId)
      tokens.push(native)
      tokens.push(new Token(defaultChainId, token.zrc20Address, token.decimals, native.symbol, native.name))
    } else if (token.type === TokenForeignTypeEnums.ERC20) {
      tokens.push(new Token(foreignChainId, token.address, token.decimals, token.symbol, token.name))
      tokens.push(new Token(defaultChainId, token.zrc20Address, token.decimals, token.symbol, token.name))
    }
  })

  tokens.push(Native.onChain(defaultChainId))

  // supportedChains.forEach((chain: any) => {
  //   try {
  //     tokens.push(
  //       new Token(
  //         parseInt(chain.chain_id, 10),
  //         getAddress('zetaToken', chain.chain_name as any),
  //         native.wrapped.decimals,
  //         native.wrapped.symbol,
  //         native.wrapped.name,
  //       ),
  //     )
  //   } catch (e) {}
  // })

  const balances: (MCurrencyAmount<mCurrency> | TokenAmount)[] = await Promise.all(
    tokens
      .map(async (token: Native | mCurrency | Token) => {
        const isGas = token instanceof Native || (token instanceof Currency && !(token instanceof Token))
        const isBitcoin = isBitcoinChain(token.chainId)

        if (isGas && !isBitcoin) {
          const rpc = BASE_RPC_SCAN_URLS[token.chainId]
          const provider = new ethers.providers.JsonRpcProvider(rpc)
          return provider
            .getBalance(evmAddress)
            .then((balance) => {
              return MCurrencyAmount.fromRawAmount(token, JSBI.BigInt(balance))
            })
            .catch(() => {
              return MCurrencyAmount.fromRawAmount(token, JSBI.BigInt(0))
            })
        }
        if (isGas && isBitcoin && btcAddress) {
          const API = getEsplora(token.chainId)
          return fetch(`${API}/address/${btcAddress}`)
            .then(async (response) => {
              const r = await response.json()
              const { funded_txo_sum, spent_txo_sum } = r.chain_stats
              const balance = (funded_txo_sum - spent_txo_sum).toString()
              return MCurrencyAmount.fromRawAmount(token, JSBI.BigInt(balance))
            })
            .catch(() => {
              return MCurrencyAmount.fromRawAmount(token, JSBI.BigInt(0))
            })
        }
        if (token instanceof Token) {
          const rpc = BASE_RPC_SCAN_URLS[token.chainId]
          const provider = new ethers.providers.JsonRpcProvider(rpc)
          const contract = new ethers.Contract(token.address, ERC20_ABI, provider)
          return contract
            .balanceOf(evmAddress)
            .then((balance: any) => {
              return new TokenAmount(token, JSBI.BigInt(balance))
            })
            .catch(() => {
              return new TokenAmount(token, JSBI.BigInt(0))
            })
        }

        return null
      })
      .filter((token) => token),
  )
  return balances
}
