/* eslint-disable lodash/import-scope */
import { Native, Token } from '@abstra-dex/sdk'
import { CHAIN_ID } from 'config/constants/networks'
import { SerializedToken } from 'config/constants/types'
import {
  ArrowDownIcon,
  Flex,
  LinkExternal,
  Text,
  useModalContext,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  AutoRenewIcon,
} from 'packages/uikit'
import React, { useRef } from 'react'
import { CrossChainStatus, TransactionCrossChainSwap } from 'state/cross-swap-transactions/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { getLogoChainId, getNameWithSymbol } from 'views/CrossSwap/helpers'
// import { CurrencyLogo } from 'components/Logo'
import { getHashExplorer } from '../../utils'
import LoadingCircle, { loadingAnimation } from '../LoadingCircle'

const CrossChainSwap: React.FC<{ transaction: TransactionCrossChainSwap }> = ({ transaction, ...props }) => {
  const isRevert =
    transaction.status === CrossChainStatus.Reverted || transaction.status === CrossChainStatus.PendingRevert
  const isAborted = transaction.status === CrossChainStatus.Aborted

  const isOutputPending = !transaction.destinationHash || transaction.status === CrossChainStatus.PendingOutbound

  const inputCurrency =
    (transaction?.inputCurrency as Token)?.chainId > 0
      ? deserializeToken(transaction.inputCurrency as SerializedToken)
      : Native.onChain(transaction.inputChain)

  const revert = isRevert || (transaction.destinationChain && transaction.destinationChain === transaction.inputChain)

  const outputCurrency =
    (transaction?.outputCurency as Token)?.chainId > 0
      ? deserializeToken(transaction.outputCurency as SerializedToken)
      : Native.onChain(transaction.outputChain)

  const { onDismiss } = useModalContext()
  const elementRef = useRef(null)

  const onRefresh = () => {
    elementRef?.current?.classList.add('spin')

    setTimeout(() => {
      elementRef?.current?.classList.remove('spin')
    }, 2000)
  }
  const debounceOnRefresh = debounce(onRefresh, 200)

  // console.log(transaction.inputChain)
  // console.log(transaction.outputChain)

  return (
    <>
      <CrossChainItem {...props}>
        <Flex justifyContent="space-between" alignItems="center" position="relative">
          <Divider />
          <InputSection flexDirection="column" alignItems="flex-start" style={{ position: 'relative', zIndex: '1' }}>
            {/* <CurrencyLogo
            currency={inputCurrency}
            style={{
              marginRight: 'auto',
            }}
          /> */}
            {transaction.inputChain && !revert ? (
              <img
                className="chain-icon"
                src={getLogoChainId(transaction.inputChain)}
                alt="input-icon"
                style={{ marginBottom: 'auto' }}
              />
            ) : (
              <img className="chain-icon" src={getLogoChainId(transaction.inputChain)} alt="output-icon" />
            )}

            <ModalLink href={getHashExplorer(transaction.inputChain, transaction.hash, true)} iconColor="blackColor">
              <Text small color="absBlackColor">
                View
              </Text>
            </ModalLink>
          </InputSection>

          <Flex
            flexDirection="column"
            alignItems="center"
            style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
          >
            {isAborted ? (
              <FailedBox>
                <img
                  className="chain-icon"
                  src="/images/cross-failed-icon.svg"
                  alt="failed-icon"
                  style={{ marginBottom: 'auto' }}
                />
              </FailedBox>
            ) : (
              <img
                className="chain-icon"
                src="/images/abstradex/svgs/zetachain-icon.svg"
                alt="icon"
                style={{ marginBottom: 'auto' }}
              />
            )}

            {isAborted ? (
              <FailedText color="red">Failed</FailedText>
            ) : transaction.zetachainHashOb ? (
              <ModalLink
                href={getHashExplorer(parseInt(CHAIN_ID, 10), transaction.zetachainHashOb, false)}
                iconColor="absBlackColor"
              >
                <Text small color="absBlackColor">
                  View
                </Text>
              </ModalLink>
            ) : (
              <LoadingCircle />
            )}
          </Flex>

          <OutputSection flexDirection="column" alignItems="flex-end" style={{ position: 'relative', zIndex: '1' }}>
            {/* <CurrencyLogo
            currency={outputCurrency}
            style={{
              marginLeft: 'auto',
            }}
          /> */}
            {isAborted ? (
              <FailedBox>
                <img
                  className="chain-icon"
                  src="/images/cross-failed-icon.svg"
                  alt="failed-icon"
                  style={{ marginBottom: 'auto' }}
                />
              </FailedBox>
            ) : transaction.outputChain && !revert ? (
              <img className="chain-icon" src={getLogoChainId(transaction.outputChain)} alt="output-icon" />
            ) : (
              <img className="chain-icon" src={getLogoChainId(transaction.inputChain)} alt="output-icon" />
            )}

            {isAborted ? (
              <FailedText color="red">Failed</FailedText>
            ) : isOutputPending ? (
              <LoadingCircle style={{ transform: 'translateX(-4px)' }} />
            ) : (
              <ModalLink
                href={getHashExplorer(transaction.destinationChain, transaction.destinationHash, true)}
                iconColor="absBlackColor"
              >
                <Text small color="absBlackColor">
                  View
                </Text>
              </ModalLink>
            )}
          </OutputSection>
        </Flex>
        <Flex alignItems="center" position="relative">
          <Text fontSize="20px" color="absBlackColor">
            {getNameWithSymbol(inputCurrency.symbol, transaction.inputChain)}
            {/* {inputCurrency.symbol} */}
          </Text>

          <Text color="absBlackColor">
            <ArrowDownIcon
              color="absBlackColor"
              width="26px"
              style={{ transform: 'rotate(-90deg) translateX(-4px)' }}
            />
          </Text>

          <Text fontSize="20px" color="absBlackColor">
            {getNameWithSymbol(outputCurrency.symbol, transaction.outputChain)}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          {isAborted ? (
            <>
              <Text small color="absMainColor">
                Transaction failed
              </Text>
            </>
          ) : revert ? (
            <>
              <Text small color="absMainColor">
                Transaction revert
              </Text>

              <Flex alignItems="center">
                <Text small color="absBlackColor">
                  Refresh
                </Text>
                <IconButton
                  ref={elementRef}
                  onClick={debounceOnRefresh}
                  variant="text"
                  style={{ padding: '0', height: 'auto' }}
                >
                  <AutoRenewIcon color="absBlackColor" />
                </IconButton>
              </Flex>
            </>
          ) : isOutputPending ? (
            <>
              <Text small color="absMainColor">
                Transaction in progress
              </Text>

              <Flex alignItems="center">
                <Text small color="absBlackColor">
                  Refresh
                </Text>
                <IconButton
                  ref={elementRef}
                  onClick={debounceOnRefresh}
                  variant="text"
                  style={{ padding: '0', height: 'auto' }}
                >
                  <AutoRenewIcon color="absBlackColor" />
                </IconButton>
              </Flex>
            </>
          ) : (
            <>
              <Text small color="absMainColor">
                Transaction successful
              </Text>
            </>
          )}
        </Flex>
      </CrossChainItem>
    </>
  )
}

export default CrossChainSwap

const Divider = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-18px);

  border: 1px solid #000;
  border-style: dashed;
`

const ModalLink = styled(LinkExternal)`
  margin-bottom: 0;
`

const CrossChainItem = styled.div`
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.absModalBg};
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
`

const IconButton = styled(Button)`
  &.spin {
    animation: ${loadingAnimation} 1.4s cubic-bezier(0.5, 0, 0.5, 1);
  }
`

const InputSection = styled(Flex)`
  & > .chain-icon {
    width: 25px;
  }
`

const OutputSection = styled(Flex)`
  & > .chain-icon {
    width: 25px;
  }
`

const FailedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.absModalBg};
`

const FailedText = styled(Text)`
  visibility: hidden;
`
