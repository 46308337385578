import React, { useState } from 'react'
import styled from 'styled-components'
// import getExternalLinkProps from '../../util/getExternalLinkProps'
// import Grid from '../../components/Box/Grid'
import Box from '../../components/Box/Box'
import Text from '../../components/Text/Text'
import Heading from '../../components/Heading/Heading'
// import { Button } from '../../components/Button'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard, { MoreWalletCard } from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'
import { Flex } from '../../components/Box'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const WalletWrapper = styled(Flex)`
  flex-direction: column-reverse;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const Line = styled.div`
  background: #bfbfbf;

  width: 100%;
  height: 1px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 2px;
    height: auto;
  }
`

const StyledModalContainer = styled(ModalContainer)`
  background: ${({ theme }) => theme.colors.absModalBg};
  height: auto;
  border-radius: 16px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 701px;
  width: calc(100% - 20px);
`

const StyledWalletCard = styled(WalletCard)`
  padding: 0;
`

const WrapperLeft = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 24px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 36px;
  }
`

const WrapperRight = styled(Flex)`
  width: 100%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;

  & > .wrapper-right {
    flex-direction: row;
    transform: translateY(0);
    padding: 0 16px 16px;

    ${({ theme }) => theme.mediaQueries.md} {
      flex-direction: column;
      transform: translateY(-25%);
    }

    & > img {
      width: 100%;
      max-width: 84px;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 161px;
      }
    }
  }
`

const GridWalletWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px 16px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    padding: 0;
  }
`

const StyledDescription = styled(Text)`
  display: none;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

const getPriority = (priority: Config['priority']) => (typeof priority === 'function' ? priority() : priority)

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => getPriority(a.priority) - getPriority(b.priority))

  const preferredWalletName = localStorage?.getItem(walletLocalStorageKey)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 5, t }) => {
  const [showMore, setShowMore] = useState(false)
  const sortedConfig = getPreferredConfig(config)
  // Filter out WalletConnect if user is inside TrustWallet built-in browser
  const walletsToShow = window.ethereum?.isTrust
    ? sortedConfig.filter((wallet) => wallet.title !== 'WalletConnect')
    : sortedConfig
  const displayListConfig = showMore ? walletsToShow : walletsToShow.slice(0, displayCount)

  return (
    <StyledModalContainer>
      <ModalHeader background="#281736">
        <ModalTitle>
          <Heading color="absBlackColor">{t('Connect Wallet')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={['100%', null, 'auto']} style={{ marginBottom: '12px' }}>
        <WalletWrapper>
          <WrapperLeft>
            <StyledDescription color="absBlackColor" fontWeight="400">
              {t(
                'Start by connecting with one of the wallets below. Be sure to store your private keys or seed phrase securely. Never share them with anyone.',
              )}
            </StyledDescription>
            <GridWalletWrapper>
              {displayListConfig.map((wallet) => (
                <Box key={wallet.title} maxWidth="100%">
                  <StyledWalletCard
                    walletConfig={wallet}
                    login={login}
                    onDismiss={onDismiss}
                    fontSize="14px"
                    fontColor="absBlackColor"
                  />
                </Box>
              ))}
              {/* {!showMore && (
                <Box>
                  <MoreWalletCard t={t} onClick={() => setShowMore(true)} />
                </Box>
              )} */}
            </GridWalletWrapper>
          </WrapperLeft>

          <Line />

          <WrapperRight>
            <Flex justifyContent="center" flexDirection="column" alignItems="center" className="wrapper-right">
              <img src="/images/abstradex/svgs/multi-wallet.svg" alt="multi-wallet" />

              <Text color="absBlackColor" fontSize="clamp(12px, 4vw, 16px)" textAlign="center">
                {t('Please confirm in your wallet')}
              </Text>
            </Flex>
          </WrapperRight>
        </WalletWrapper>
      </ModalBody>
    </StyledModalContainer>
  )
}

export default ConnectModal
