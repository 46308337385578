import { useVariableLoaded } from 'hooks/useVariableLoaded'
import { useMemo } from 'react'
import { authenticatedBitcoin } from 'contexts/DefiContext'
import { useBitcoinAddress } from 'contexts/DefiContext/hook'
import { XFIWalletProviderProvider } from './connectors/XdefiWalet'
import { UniSatWalletProviderProvider } from './connectors/UniSatWallet'
import { XverseWalletProvider } from './connectors/XverseWallet'

export enum WalletBitcoinConnectorEnums {
  XFI = 'XFI',
  UniSat = 'UniSat',
  Xverse = 'Xverse',
}

export const useXFI = () => {
  const xfi = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.xfi && w?.xfi?.installed && w?.xfi?.bitcoin
  })

  return useMemo(() => (xfi ? (window as any)?.xfi?.bitcoin : null), [xfi])
}

export const useUnisat = () => {
  const unisat = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.unisat && w?.unisat?._initialized
  })

  return useMemo(() => (unisat ? (window as any)?.unisat : null), [unisat])
}

export const useXverse = () => {
  const xverse = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.XverseProviders?.BitcoinProvider
  })

  return useMemo(() => (xverse ? (window as any)?.XverseProviders?.BitcoinProvider : null), [xverse])
}

export const useWalletBitcoinProviders = () => {
  const xfi = useXFI()
  const uniSat = useUnisat()
  const xverse = useXverse()

  return useMemo(
    () => ({
      [WalletBitcoinConnectorEnums.XFI]: xfi ? new XFIWalletProviderProvider(xfi) : null,
      [WalletBitcoinConnectorEnums.UniSat]: uniSat ? new UniSatWalletProviderProvider(uniSat) : null,
      [WalletBitcoinConnectorEnums.Xverse]: xverse ? new XverseWalletProvider(xverse) : null,
    }),
    [xfi, uniSat, xverse],
  )
}

export const useWalletBitcoinProviderByWallet = () => {
  const bitcoinAddress = useBitcoinAddress()
  const walletBitcoinProvider = useWalletBitcoinProviders()

  return useMemo(() => {
    const wallet = localStorage.getItem(authenticatedBitcoin)
    return bitcoinAddress ? walletBitcoinProvider[wallet] : null
  }, [walletBitcoinProvider, bitcoinAddress])
}
