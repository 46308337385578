import { ChainId } from '@abstra-dex/sdk'

export const getLogoChainId = (chainId: number) => {
  switch (chainId) {
    case 5:
      return '/images/chain/weth-icon.svg'
      break
    case 97:
      return '/images/chain/bnb-icon.svg'
      break
    case 80001:
      return '/images/chain/matic-icon.svg'
      break
    case 7001:
      return '/images/abstradex/svgs/zetachain-icon.svg'
      break
    case 18332:
      return '/images/chain/btc-icon.svg'
      break
    case 56:
      return '/images/chain/bnb-icon.svg'
      break
    case 7000:
      return '/images/abstradex/svgs/zetachain-icon.svg'
      break
    case 1:
      return '/images/chain/weth-icon.svg'
      break
    case 8332:
      return '/images/chain/btc-icon.svg'
      break
    default:
      return '/images/abstradex/svgs/zetachain-icon.svg'
  }
}

export const getNameWithSymbol = (symbol: any, chainId: ChainId) => {
  if (chainId === ChainId.BTC_MAINNET) return 'BTC Native'
  return symbol
}
