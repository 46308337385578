import { createReducer } from '@reduxjs/toolkit'
import { BTC_CHAIN_ID, CHAIN_ID } from 'config/constants/networks'
import { Field } from 'state/swap/actions'
import { selectCurrency, switchCurrencies, typeInput } from './actions'
import { CrossSwapState } from './types'

const initialState: CrossSwapState = {
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
    chainId: parseInt(CHAIN_ID, 10),
  },
  [Field.OUTPUT]: {
    currencyId: '',
    chainId: BTC_CHAIN_ID,
  },
  transactions: [],
}

export default createReducer<CrossSwapState>(initialState, (builder) =>
  builder
    .addCase(selectCurrency, (state, { payload: { currencyId, chainId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId && chainId === state[otherField].chainId) {
        // the case where we have to swap the order
        return {
          ...state,
          [field]: { currencyId, chainId },
          [otherField]: { currencyId: state[field].currencyId, chainId: state[field].chainId },
        }
      }
      // the normal case
      return {
        ...state,
        [field]: { currencyId, chainId },
      }
    })
    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId, chainId: state[Field.OUTPUT].chainId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId, chainId: state[Field.INPUT].chainId },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    }),
)
