import { ChainId } from '@abstra-dex/sdk'

export const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID

export const BTC_CHAIN_ID = Number(CHAIN_ID) === ChainId.TESTNET ? ChainId.BTC_TESTNET : ChainId.BTC_MAINNET

export const chainIds: ChainId[] = [
  ChainId.BTC_MAINNET,
  ChainId.BTC_TESTNET,
  ChainId.MAINNET,
  ChainId.TESTNET,
  ChainId.BSC_MAINNET,
  ChainId.BSC_TESTNET,
  ChainId.ETH,
  ChainId.GÖRLI,
  ChainId.MUMBAI,
  ChainId.POLYGON,
]
