/* eslint-disable no-useless-constructor */
import { ChainId, TokenAmount } from '@abstra-dex/sdk'
import { ActionCrossChainSwapBTC } from 'contexts/DefiContext'
import { CrossSwapTrade, getAddressZrc20ByTokenAmount } from 'state/cross-swap/types'
import { clear0xAddress, getOmnichainAddress, getTssAddress } from 'utils/addressHelpers'
import { isTestnet } from 'utils/network'
import { unwrappedCurrencyAmount } from 'utils/wrappedCurrencyCross'
import { BasePropsTransfer, BitcoinAccountWallet, FEE_RATE, RequestProps, WalletProvider } from './base'

enum BitcoinNetworkEnum {
  BITCOIN_TESTNET = 'testnet',
  BITCOIN_MAINNET = 'mainnet',
}
type IWalletProvider = {
  call: (...args) => void
  callBacksMap: any
  chainId: ChainId
  connect: (...args) => void
  getAccounts: () => Promise<any>
  network: BitcoinNetworkEnum
  requestAccounts: () => Promise<any>
  request: (args: RequestProps, callback?: (error: any, hash: any) => void) => any
  transfer: (args: BasePropsTransfer) => Promise<string>
  changeNetwork: (network: BitcoinNetworkEnum) => void
  signTransaction: (...args) => any
}

export class XFIWalletProviderProvider extends WalletProvider<IWalletProvider> {
  public connect = async () => {
    return this.getAccounts()
  }

  public getAccounts = async (): Promise<BitcoinAccountWallet[]> => {
    const accounts = await this.provider.getAccounts()

    return accounts.map(
      (account): BitcoinAccountWallet => ({
        address: account,
        publicKey: '',
      }),
    )
  }

  public getAccount = async (): Promise<BitcoinAccountWallet> => {
    const account = (await this.getAccounts())?.[0]
    return account
  }

  public changeNetwork = async (chainId: ChainId) => {
    return this.provider.changeNetwork(
      isTestnet(chainId) ? BitcoinNetworkEnum.BITCOIN_TESTNET : BitcoinNetworkEnum.BITCOIN_MAINNET,
    )
  }

  public request = (data: RequestProps, callback?: (error: any, hash: any) => void) => {
    return this.provider.request(data, callback)
  }

  public transfer = ({ feeRate, from, recipient, tokenAmount, memo }: BasePropsTransfer): Promise<string> => {
    const amount = tokenAmount.raw.toString()

    return new Promise((resolve, reject) =>
      this.request(
        {
          method: 'transfer',
          params: [
            {
              feeRate,
              from,
              recipient,
              amount: {
                amount,
                decimals: tokenAmount?.currency?.decimals || tokenAmount?.token?.decimals,
              },
              memo,
            },
          ],
        },
        (error, hash: string) => {
          if (error) {
            reject(error)
          } else {
            resolve(hash)
          }
        },
      ),
    )
  }

  public depositBTC = ({
    evmAddress,
    from,
    chainId,
    tokenAmount,
  }: {
    from: string
    chainId: ChainId
    evmAddress: string
    tokenAmount: TokenAmount
  }) => {
    const memo = `hex::${clear0xAddress(evmAddress)}`

    return this.transfer({
      from,
      recipient: getTssAddress(chainId),
      feeRate: FEE_RATE,
      memo,
      tokenAmount,
    })
  }

  public swapBTC = ({
    from,
    action,
    trade,
  }: {
    from: string
    trade: CrossSwapTrade
    action: typeof ActionCrossChainSwapBTC[keyof typeof ActionCrossChainSwapBTC]
  }) => {
    const contract = clear0xAddress(getOmnichainAddress(trade.inputChainId))
    const zrc20 = clear0xAddress(
      trade.outputIsNative
        ? getAddressZrc20ByTokenAmount(unwrappedCurrencyAmount(trade.outputTokenAmount), trade.outputChainId)
        : trade.outputTokenAmount.token.address,
    )
    const dest = clear0xAddress(trade.recipient)
    const memo = `hex::${contract}${action}${zrc20}${dest}`

    return this.transfer({
      from,
      recipient: getTssAddress(trade.inputChainId),
      feeRate: FEE_RATE,
      memo,
      tokenAmount: trade.inputTokenAmount,
    })
  }
}
