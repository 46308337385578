/* eslint-disable @typescript-eslint/no-empty-function */
import { ChainId, MCurrencyAmount, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDebounce from 'hooks/useDebounce'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { fetchFees, getBalances } from './helpers'

export type DefiFees = {
  feesZRC20: {
    [chainId in ChainId]: TokenAmount
  }
  feesZNative: {
    [chainId in ChainId]: MCurrencyAmount<mCurrency>
  }
}

export type DefiWalletContextProps = {
  bitcoinAddress: string
  updateBitcoinAddress: (address: string) => void

  balances: (TokenAmount | MCurrencyAmount<mCurrency>)[]
  balanceLoading: boolean
  refetchBalances: () => void
  clearContext: () => void
  fees: DefiFees
}

export const DefiWalletContext = createContext<DefiWalletContextProps>({
  bitcoinAddress: '',
  updateBitcoinAddress: () => {},

  balances: [],

  refetchBalances: () => {},
  balanceLoading: false,

  clearContext: () => {},

  fees: {
    feesZRC20: null,
    feesZNative: null,
  },
})

const DefiContext: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [bitcoinAddress, setBitcoinAddress] = useState<string>('')
  const { account } = useActiveWeb3React()

  const [balances, setBalances] = useState<(TokenAmount | MCurrencyAmount<mCurrency>)[]>([])
  const [fees, setFees] = useState<DefiFees>({
    feesZRC20: null,
    feesZNative: null,
  })

  const [balanceLoading, setBalanceLoading] = useState(false)

  const isConnected = useDebounce(account, 1000)

  const handleSetBitcoinAddress = useCallback((address: string) => setBitcoinAddress(address), [setBitcoinAddress])

  const fetchBalances = useCallback(async () => {
    setBalanceLoading(true)

    if (balanceLoading) return

    try {
      if (!isConnected) {
        setBalances([])
        return
      }
      const bitcoinBalance = await getBalances(account, bitcoinAddress)
      if (bitcoinBalance) setBalances(bitcoinBalance)
    } catch (e) {
      console.error(e)
    } finally {
      setBalanceLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, bitcoinAddress, isConnected])

  const fetchFeesList = useCallback(async () => {
    try {
      if (!isConnected) {
        return setFees({
          feesZRC20: null,
          feesZNative: null,
        })
      }
      const fee = await fetchFees()

      if (fee) setFees(fee)
    } catch (e) {
      console.error(e)
    }
    return true
  }, [isConnected])

  const clearContext = useCallback(() => {
    if (!account) return

    setBitcoinAddress('')
    setBalances([])
    setFees({ feesZRC20: null, feesZNative: null })
  }, [account])

  useEffect(() => {
    if (isConnected && !balanceLoading) {
      fetchBalances()
      fetchFeesList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBalances, fetchFeesList, isConnected, bitcoinAddress])

  const value = useMemo(
    () => ({
      bitcoinAddress,
      balances,
      balanceLoading,
      fees,
      clearContext,
      refetchBalances: fetchBalances,
      updateBitcoinAddress: handleSetBitcoinAddress,
    }),
    [balanceLoading, balances, fees, bitcoinAddress, clearContext, fetchBalances, handleSetBitcoinAddress],
  )
  return <DefiWalletContext.Provider value={value}>{children}</DefiWalletContext.Provider>
}
export default DefiContext
