import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '48px',
    padding: '12px 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: '#000000',
    color: '#9B9C9F',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: 'absMainColor',
    boxShadow: 'none',
    color: 'primary',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderColor: 'absMainColor',
    boxShadow: 'none',
    color: '#000000',
  },
  [variants.SUBTLE]: {
    backgroundColor: '#cbd3bf',
    color: '#000000',
  },
  [variants.DANGER]: {
    backgroundColor: 'absMainColor',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'absBlackColor',
    fontWeight: '800',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.ACTIVE]: {
    backgroundColor: 'absMainColor',
    color: 'absWhiteColor',
  },

  [variants.BUTTON]: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },

  [variants.DURATION]: {
    backgroundColor: 'absMainColor',
    color: 'absBlackColor',
  },
  [variants.CUSTOM]: {
    backgroundColor: 'absMainColor',
    borderRadius: '25px',
    color: 'absWhiteColor',
    fontWeight: '400',
  },
  [variants.ABS]: {
    backgroundColor: 'absMainColor',
    borderRadius: '8px',
    color: 'absBlackColor',
    fontWeight: '400',
  },
  [variants.CLAIM]: {
    backgroundColor: '#D2D2D2',
    color: 'absBlackColor',
    fontWeight: '600',
  },
  [variants.BUY]: {
    backgroundColor: 'absMainColor',
    borderRadius: '8px',
    color: 'text',
    fontWeight: '400',
  },
}
