import { useCallback } from 'react'
import { Box, Text, Flex, CloseIcon, OpenNewIcon, useModal } from 'packages/uikit'
import { TransactionWrapper } from 'views/CrossSwap/styles'
import { openTransactionHistories } from 'state/user/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { useCrossChainTransaction } from 'state/cross-swap-transactions/hooks'
import ModalTransactionHistory from './Modal/ModalTransactionHistory'
import CrossChainTransaction from './CrossChainTransaction'

const TransactionHistories = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>()
  const transactions = useCrossChainTransaction()
  const [onPresentOpenTransactionModal] = useModal(
    <ModalTransactionHistory />,
    false,
    false,
    'transaction-cross-chain-modal',
  )

  const onClose = useCallback(() => {
    dispatch(openTransactionHistories(false))
  }, [dispatch])

  const openModalHistories = useCallback(() => {
    onPresentOpenTransactionModal()
    onClose()
  }, [onClose, onPresentOpenTransactionModal])

  return (
    <Box {...props} borderRadius="8px">
      <Flex justifyContent="space-between" mb="16px">
        <Flex>
          <Text fontSize="18px" ml="8px">
            Transaction History
          </Text>
          <OpenNewIcon style={{ cursor: 'pointer' }} onClick={openModalHistories} />
        </Flex>

        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </Flex>
      <TransactionWrapper width="100%" maxWidth="378px" maxHeight="439px" overflowY="auto">
        {transactions.map((txn) => {
          return <CrossChainTransaction transaction={txn} key={txn.hash} />
        })}
      </TransactionWrapper>
    </Box>
  )
}

export default TransactionHistories
