import { ChainId } from '@abstra-dex/sdk'
import { CrossChainTransaction } from 'state/cross-swap-transactions/types'

export const getHashExplorer = (chainId: ChainId, hash: string, cross?: boolean) => {
  if (!hash) return '#'

  switch (chainId) {
    case ChainId.BTC_MAINNET:
      return `https://live.blockcypher.com/btc/tx/${hash}/`
    case ChainId.BTC_TESTNET:
      return `https://live.blockcypher.com/btc-testnet/tx/${hash}/`

    case ChainId.MAINNET:
      return cross ? `https://zetachain.blockscout.com/tx/${hash}` : `https://explorer.zetachain.com/tx/${hash}`

    case ChainId.TESTNET:
      return cross
        ? `https://zetachain-athens-3.blockscout.com/tx/${hash}`
        : `https://athens.explorer.zetachain.com/tx/${hash}`

    case ChainId.POLYGON:
      return `https://polygonscan.com/tx/${hash}`
    case ChainId.MUMBAI:
      return `https://mumbai.polygonscan.com/tx/${hash}`

    case ChainId.BSC_MAINNET:
      return `https://bscscan.com/tx/${hash}`
    case ChainId.BSC_TESTNET:
      return `https://testnet.bscscan.com/tx/${hash}`

    case ChainId.ETH:
      return `https://etherscan.io/tx/${hash}`
    case ChainId.GÖRLI:
      return `https://goerli.etherscan.io/tx/${hash}`

    default:
      return '#'
  }
}

export const mapperTxHash = (txn, transaction): CrossChainTransaction => {
  // const data = {
  //   CrossChainTxs: [
  //     {
  //       cctx_status: {
  //         lastUpdate_timestamp: '1705324910',
  //         status: 'OutboundMined',
  //         status_message: 'Remote omnichain contract call completed',
  //       },
  //       creator: 'zeta1j8g8ch4uqgl3gtet3nntvczaeppmlxajqwh5u6',
  //       inbound_tx_params: {
  //         amount: '5000',
  //         asset: '',
  //         coin_type: 'Gas',
  //         inbound_tx_ballot_index: '0xe270450d16a3e8ffc3817b58961c1d6bd31be42fd84f3fe943f8e5d89bd13f2a',
  //         inbound_tx_finalized_zeta_height: '3217316',
  //         inbound_tx_observed_external_height: '2572942',
  //         inbound_tx_observed_hash: '2734e83378327a69fbea4013feeb4db18339cc91262446b2342d3b211592efb1',
  //         sender: 'tb1qk9axjcpesyl6yumxtffxd9hclzr903kezg4mtd',
  //         sender_chain_id: '18332',
  //         tx_origin: 'tb1qk9axjcpesyl6yumxtffxd9hclzr903kezg4mtd',
  //       },
  //       index: '0xe270450d16a3e8ffc3817b58961c1d6bd31be42fd84f3fe943f8e5d89bd13f2a',
  //       outbound_tx_params: [
  //         {
  //           amount: '0',
  //           coin_type: 'Gas',
  //           outbound_tx_ballot_index: '',
  //           outbound_tx_effective_gas_limit: '0',
  //           outbound_tx_effective_gas_price: '0',
  //           outbound_tx_gas_limit: '0',
  //           outbound_tx_gas_price: '',
  //           outbound_tx_gas_used: '0',
  //           outbound_tx_hash: '0x076f18fd7ea5e47c260e76d92f0c3047bfc4a4f6db3596da4c62866cf8cbfbfe',
  //           outbound_tx_observed_external_height: '3217316',
  //           outbound_tx_tss_nonce: '0',
  //           receiver: 'tb1qk9axjcpesyl6yumxtffxd9hclzr903kezg4mtd',
  //           receiver_chainId: '7001',
  //           tss_pubkey: 'zetapub1addwnpepq28c57cvcs0a2htsem5zxr6qnlvq9mzhmm76z3jncsnzz32rclangr2g35p',
  //         },
  //       ],
  //       relayed_message:
  //         '1638de1169092fe692a6c6fd027256bb699d4db4fd88e2216e53cb5d4edbd55c874893e74d9e590713a0c5930c028511dc02665e7285134b6d11a5f4',
  //       zeta_fees: '0',
  //     },
  //   ],
  // }
  const sourceChainHash = txn.inbound_tx_params?.inbound_tx_observed_hash || ''
  const sourceChainId = Number(txn.inbound_tx_params?.sender_chain_id)
  const zetachainHashOb = txn.index || ''

  const { status, lastUpdate_timestamp: updateTime } = txn.cctx_status

  const destinationHashOb = txn.outbound_tx_params.map((output: any) => {
    return { hash: output.outbound_tx_hash, chainId: Number(output.receiver_chainId) }
  })

  const destinationHash = destinationHashOb[destinationHashOb.length - 1]?.hash || ''
  const destinationChain = destinationHashOb[destinationHashOb.length - 1]?.chainId

  return {
    ...transaction,
    status,
    sourceChainHash,
    sourceChainId,

    zetachainHashOb,
    destinationChain,
    destinationHash,
  }
}
