import { Colors } from './types'

export const baseColors = {
  failure: '#000',
  primary: '#ffffff',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  secondary: '#7645D9',
  success: '#005741',
  warning: '#000',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: '#452a7a',
  gold: '#FFC700',
  silver: '#B2B2B2',
  bronze: '#E7974D',
}

const abstradexBaseColors = {
  absBgColor: '#010100',
  absMainColor: '#005741',
  absTopBottomColor: '#0d0d0f',
  absWhiteColor: '#ffffff',
  absWhiteColorLight: 'rgba(255,255,255,0.8)',
  absBlackColor: '#000000',
  absModalBg: '#D1FAE5',
  absInputNumbericColor: '#9b9c9f',
  absInvertedContrastColor: '#323436',
  secondaryColor: '#06DEA8',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  ...abstradexBaseColors,
  background: '#FAF9FA',
  backgroundMT: '#201f39',
  backgroundActive: '#fff5e1',
  backgroundButton: '#281736',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: '#FFFFFF',
  cardBorder: '#E7E3EB',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#222426',
  invertedContrast: '#FFFFFF',
  input: '#FAF1EA',
  inputSecondary: '#d7caec',
  tertiary: '#999999',
  text: '#ffffff',
  textDisabled: '#BDC2C4',
  textSubtle: '#ffffff',
  textActive: '#ffffff',
  textColor: '#2d5000',
  textScroll: '#999999',
  disabled: '#E9EAEB',
  gradients: {
    bubblegum: 'linear-gradient(180deg, #E3CCCB -34.7%, #F9F1EA 79.3%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    abs: 'linear-gradient(to right, #2a3fa9, #14f194)',
  },
  toggle: '#756072',
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  ...abstradexBaseColors,
  secondary: '#9A6AFF',
  background: '#08060B',
  backgroundMT: '#201f39',
  backgroundActive: '#0F0E35',
  backgroundButton: '#281736',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: '#FFFFFF',
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#FAF1EA',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#353547',
  text: '#ffffff',
  textColor: '#2d5000',
  textDisabled: '#666171',
  textSubtle: '#ffffff',
  textActive: '#ffffff',
  textScroll: '#999999',
  disabled: '#524B63',
  gradients: {
    bubblegum: 'linear-gradient(180deg, #E3CCCB -34.7%, #F9F1EA 79.3%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
    abs: 'linear-gradient(to right, #2a3fa9, #14f194)',
  },
  toggle: '#756072',
}
