import {
  ChainId,
  Currency,
  CurrencyAmount,
  MCurrencyAmount,
  Native,
  Token,
  TokenAmount,
  WETH,
  mCurrency,
} from '@abstra-dex/sdk'

export function wrappedCurrency(
  currency: mCurrency | Currency | Token | undefined,
  chainId: ChainId | undefined,
): Token | undefined {
  const native = Native.onChain(chainId)

  return chainId && currency === native ? native.wrapped : currency instanceof Token ? currency : undefined
}

export function tryWrappedCurrencyAmount(
  currency: Currency | Token,
  amount: string, // raw
  chainId: ChainId | undefined,
): TokenAmount | undefined {
  const token = currency && chainId ? wrappedCurrency(currency, chainId) : undefined
  return token && currency ? new TokenAmount(token, amount) : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined,
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedCurrencyAmount(tokenAmount: TokenAmount): CurrencyAmount | undefined {
  const token = tokenAmount && tokenAmount?.token?.chainId ? unwrappedToken(tokenAmount.token) : undefined
  return token && tokenAmount ? MCurrencyAmount.fromRawAmount<any>(token, tokenAmount.raw.toString()) : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH[token.chainId])) return Native.onChain(token.chainId)
  return token
}
