import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Button, Text, Link, HelpIcon } from 'packages/uikit'
import { setupNetwork } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
// import darkTheme from 'packages/uikit/src/theme/dark'
import { AbstradexButton } from 'components/abstradex/components/ui'

const StyledLink = styled(Link)`
  width: 100%;
  &:hover {
    text-decoration: initial;
  }
`

const HelpButton = styled(Button)`
  border-radius: 8px;
  border-color: ${({ theme }) => theme.colors.absMainColor};
  color: ${({ theme }) => theme.colors.absBlackColor};
`

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector, library } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
    onDismiss?.()
  }

  return (
    <>
      <Text color="absBlackColor" mb="24px">
        {t('You’re connected to the wrong network.')}
      </Text>
      {connector instanceof InjectedConnector && (
        <AbstradexButton.AbstradexStyledActionButton onClick={handleSwitchNetwork} mb="24px">
          {t('Switch Network')}
        </AbstradexButton.AbstradexStyledActionButton>
      )}
      <StyledLink href="https://docs.abstradex.xyz/zetachain-tesnet/how-to-connect-wallet" external>
        <HelpButton width="100%" variant="secondary">
          {t('Learn How')}
          <HelpIcon color="absBlackColor" ml="6px" />
        </HelpButton>
      </StyledLink>
    </>
  )
}

export default WalletWrongNetwork
