import { Text, Flex, UserMenuItem, OpenNewIcon } from 'packages/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { SliceAccount } from 'utils/helpers'
import styled from 'styled-components'
import { FlexGap } from 'components/Layout/Flex'
import { useBitcoinAddress } from 'contexts/DefiContext/hook'

export const BITCOIN_BASE_ADDRES_URL = 'https://live.blockcypher.com/btc/address'
export const ZETA_BASE_ADDRESS_URL = 'https://zetachain.blockscout.com/address'

const AccountInfo = () => {
  const { account } = useActiveWeb3React()
  const bitcoinAddress = useBitcoinAddress()

  return (
    <>
      <Flex flexDirection="column" style={{ paddingTop: '16px' }}>
        <Text fontSize="14px">Account</Text>

        <FlexGap flexDirection="column" gap="8px">
          {bitcoinAddress ? (
            <StyledUserMenuItem as="a" target="_" href={`${BITCOIN_BASE_ADDRES_URL}/${bitcoinAddress}`}>
              <Flex>
                <img src="/images/chain/btc-icon.svg" alt="account-icon" />
                <Text>{SliceAccount(bitcoinAddress, 6, 8)}</Text>
              </Flex>
              <OpenNewIcon />
            </StyledUserMenuItem>
          ) : null}

          <StyledUserMenuItem as="a" target="_" href={`${ZETA_BASE_ADDRESS_URL}/${account}`}>
            <Flex>
              <img src="/images/abstradex/svgs/zetachain-icon.svg" alt="account-icon" />
              <Text>{SliceAccount(account, 6, 8)}</Text>
            </Flex>

            <OpenNewIcon />
          </StyledUserMenuItem>
        </FlexGap>
        <div style={{ width: '100%', height: '1px', background: 'white' }} />
      </Flex>
    </>
  )
}

export default AccountInfo

const StyledUserMenuItem = styled(UserMenuItem)`
  padding: 0;
  height: auto;
`
