/* eslint-disable no-await-in-loop */
/* eslint-disable no-useless-constructor */
import { ChainId, TokenAmount } from '@abstra-dex/sdk'
import { getAddresses } from '@ordzaar/ordit-sdk/xverse'
import { BTC_CHAIN_ID, CHAIN_ID } from 'config/constants/networks'
import { clear0xAddress, getOmnichainAddress, getTssAddress } from 'utils/addressHelpers'
import { isTestnet } from 'utils/network'
import { BasePropsTransfer, BitcoinAccountWallet, FEE_RATE, RequestProps, WalletProvider } from './base'

enum BitcoinNetworkEnum {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}

type IWalletProvider = {
  call: (...args) => void
  callBacksMap: any
  connect: (...args) => void
  createInscription: (...args) => Promise<void>
  createRepeatInscriptions: (...args) => Promise<void>
  sendBtcTransaction: (...args) => Promise<void>
  signMessage: (...args) => Promise<void>
  signMultipleTransactions: (...args) => Promise<void>
  signTransaction: (...args) => Promise<void>
}

export class XverseWalletProvider extends WalletProvider<IWalletProvider> {
  public connect = async () => {
    return null
  }

  public getAccounts = async (): Promise<BitcoinAccountWallet[]> => {
    const accounts = await getAddresses(
      isTestnet(parseInt(CHAIN_ID, 10)) ? BitcoinNetworkEnum.TESTNET : BitcoinNetworkEnum.MAINNET,
    )
    const p2sh = accounts.find((walletAddress) => walletAddress.format === 'p2sh-p2wpkh')
    // const taproot = accounts.find((walletAddress) => walletAddress.format === 'taproot') // ordinals address

    return [
      {
        address: p2sh.address,
        publicKey: p2sh.publicKey,
      },
    ]
  }

  public getAccount = async (): Promise<BitcoinAccountWallet> => {
    const account = (await this.getAccounts())?.[0]
    return account
  }

  public changeNetwork = async (chainId: ChainId) => {
    return null
  }

  public getNetwork = (): BitcoinNetworkEnum => {
    return isTestnet(parseInt(CHAIN_ID, 10)) ? BitcoinNetworkEnum.TESTNET : BitcoinNetworkEnum.MAINNET
  }

  public request = (data: RequestProps, callback?: (error: any, hash: any) => void) => {
    return this.provider.signTransaction(data, callback)
  }

  public transfer = async ({ recipient, from, memo }: BasePropsTransfer): Promise<string> => {
    // const amount = 1600 // Amount in satoshis

    // const account = await this.getAccount()
    // const psbt = new bitcoin.Psbt({ network: bitcoin.networks.testnet })
    // const OP_RETURN = Utils.compileMemo(memo)
    // const client = new Client(Network.TEST, 'https://api.blockchair.com/bitcoin/testnet/dashboards')
    // await client.scanUTXOs(from)

    // psbt.addOutput({
    //   script: bitcoin.script.compile([bitcoin.opcodes.OP_RETURN, data]),
    //   value: 0,
    // })

    // const publicKey = Buffer.from(account.publicKey, 'hex')
    // const p2wpkh = btc.p2wpkh(publicKey)
    // const p2sh = btc.p2sh(p2wpkh)

    // client.utxos.forEach((UTXO) => {
    //   psbt.addInput({
    //     hash: UTXO.hash,
    //     index: UTXO.index,
    //     witnessUtxo: UTXO.witnessUtxo,
    //     redeemScript: Buffer.from(p2sh.redeemScript),
    //   })
    // })
    // const send = await sendBtcTransaction({
    //   payload: {
    //     network: {
    //       type: BitcoinNetworkType.Testnet,
    //     },
    //     recipients: [
    //       {
    //         address: recipient,
    //         amountSats: ethers.toBigInt(amount),
    //       },
    //     ],
    //     senderAddress: from,
    //   },
    //   onFinish: (response) => {
    //     console.log('response:', response)
    //     return response
    //   },
    //   onCancel: () => {
    //     return null
    //   },
    // })
    // psbt.addOutput({ address: recipient, value: amount })
    // psbt.addOutput({ script: OP_RETURN, value: 0 })
    // const psbtBase64 = psbt.toBase64()

    // const signPsbtOptions = {
    //   payload: {
    //     network: {
    //       type: BitcoinNetworkType.Testnet,
    //     },
    //     message: 'Sign Transaction',
    //     psbtBase64,
    //     broadcast: false,
    //     inputsToSign: [
    //       {
    //         address: from,
    //         signingIndexes: [0],
    //       },
    //     ],
    //   },
    //   onFinish: async (response) => {
    //     console.log(response)
    //   },
    //   onCancel: () => {
    //     console.log('failed')
    //   },
    // }

    // // const signedTx = await this.provider.signTransaction({ psbt: psbtBase64 })

    // return new Promise((resolve, reject) => {
    //   return signTransaction(signPsbtOptions)
    //     .then((res: any) => {
    //       console.log(res)

    //       resolve(res)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // })

    return null
  }

  public depositBTC = ({
    evmAddress,
    from,
    tokenAmount,
  }: {
    from: string
    evmAddress: string
    tokenAmount: TokenAmount
  }) => {
    const memo = `hex::${clear0xAddress(evmAddress)}`

    return this.transfer({
      from,
      recipient: getTssAddress(BTC_CHAIN_ID),
      feeRate: FEE_RATE,
      memo,
      tokenAmount,
    })
  }

  public swapBTC = ({
    evmAddress,
    from,
    inputTokenAmount,
    outputTokenAmount,
    action,
  }: {
    from: string
    evmAddress: string
    inputTokenAmount: TokenAmount
    outputTokenAmount: TokenAmount
    action: '01' | '02'
  }) => {
    const contract = clear0xAddress(getOmnichainAddress(inputTokenAmount.token.chainId as any))
    const zrc20 = clear0xAddress(outputTokenAmount.token.address)
    const dest = clear0xAddress(evmAddress)

    const memo = `hex::${contract}${action}${zrc20}${dest}`

    return this.transfer({
      from,
      recipient: getTssAddress(BTC_CHAIN_ID),
      feeRate: FEE_RATE,
      memo,
      tokenAmount: inputTokenAmount,
    })
  }
}
