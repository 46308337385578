import { MCurrencyAmount, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import { useContext, useMemo } from 'react'
import { CrossSwapTrade } from 'state/cross-swap/types'
import { MethodCrossSwap } from '../../config/constants/cross-chain'
import { DefiWalletContext } from './DefiContext'

export const useAuthBitcoinContext = () => {
  const { bitcoinAddress, updateBitcoinAddress } = useContext(DefiWalletContext)
  return useMemo(() => ({ bitcoinAddress, updateBitcoinAddress }), [bitcoinAddress, updateBitcoinAddress])
}

export const useBitcoinAddress = () => {
  const { bitcoinAddress } = useContext(DefiWalletContext)
  return useMemo(() => bitcoinAddress, [bitcoinAddress])
}
export const useBitcoinLogout = () => {
  const { clearContext } = useContext(DefiWalletContext)
  return useMemo(() => clearContext, [clearContext])
}

export const useDefiBalances = () => {
  const { balanceLoading, balances } = useContext(DefiWalletContext)
  return useMemo(
    () => ({
      balances,
      balanceLoading,
    }),
    [balances, balanceLoading],
  )
}

export const usefees = () => {
  const { fees } = useContext(DefiWalletContext)
  return useMemo(() => fees, [fees])
}

export const usefeeByMethodCrossSwap = (trade: CrossSwapTrade): MCurrencyAmount<mCurrency> | TokenAmount | null => {
  const { fees } = useContext(DefiWalletContext)
  return useMemo(() => {
    if ([MethodCrossSwap.withdrawERC20].includes(trade.methodSwap) && !trade.outputIsNative) {
      return fees?.feesZRC20?.[trade.outputChainId]
    }

    if ([MethodCrossSwap.crossChainZeta].includes(trade.methodSwap) && trade.outputIsNative) {
      return fees?.feesZNative?.[trade.outputChainId]
    }

    if (
      [
        MethodCrossSwap.crossChainSwap,
        MethodCrossSwap.crossChainSwapTransfer,
        MethodCrossSwap.crossChainSwapNative,
        MethodCrossSwap.crossChainSwapNativeTransfer,
        MethodCrossSwap.crossChainSwapBTC,
        MethodCrossSwap.crossChainSwapBTCTransfer,
        MethodCrossSwap.crossChainSwapBTCTransferNative,
      ].includes(trade.methodSwap)
    ) {
      return fees?.feesZNative?.[trade.outputChainId]
    }

    return null
  }, [fees, trade])
}

export const useDefiFetchBalances = () => {
  const { balanceLoading, refetchBalances } = useContext(DefiWalletContext)

  return useMemo(() => {
    return {
      balanceLoading,
      refetchBalances,
    }
  }, [balanceLoading, refetchBalances])
}
