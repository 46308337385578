/* eslint-disable consistent-return */
import { getCosmosHttp } from 'contexts/DefiContext/helpers/endpoints'
import { useDefiFetchBalances } from 'contexts/DefiContext/hook'
import { ethers } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'
import { isBitcoinChain } from 'utils/network'
import { mapperTxHash } from 'views/CrossSwap/utils'
import debounce from 'lodash/debounce'
import { BASE_RPC_SCAN_URLS } from 'config'
import useDebounce from 'hooks/useDebounce'
import { useCrossChainTransactionPendings, useUpdateTransactionCrossSwap } from './hooks'
import { CrossChainStatus, CrossChainTransaction } from './types'

const getInTxHashToCctxData = (hash: string) => {
  const api = getCosmosHttp()

  try {
    return fetch(`${api}/zeta-chain/crosschain/in_tx_hash_to_cctx_data/${hash}`)
      .then(async (res) => {
        const data = await res.json()
        return data?.CrossChainTxs?.[0]
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

const getInTxHashToCctx = (hash: string) => {
  const api = getCosmosHttp()

  try {
    return fetch(`${api}/zeta-chain/crosschain/inTxHashToCctx/${hash}`)
      .then(async (res) => {
        const data = await res.json()
        return data?.inTxHashToCctx
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

const getCctxDetails = (hash: string) => {
  const api = getCosmosHttp()

  try {
    return fetch(`${api}/zeta-chain/crosschain/cctx/${hash}`)
      .then(async (res) => {
        const data = await res.json()
        return data?.CrossChainTx
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

enum TransactionStatus {
  Success = 1,
  Failed = 0,
}

const CrossChainTransactionUpdater = () => {
  const transactionPendings = useCrossChainTransactionPendings()
  const updateTransaction = useUpdateTransactionCrossSwap()
  const { refetchBalances } = useDefiFetchBalances()

  const debounceTxn = useDebounce(transactionPendings, 2000)
  const txnInbounds = useMemo(() => debounceTxn.filter((txn) => txn.status === CrossChainStatus.Inbound), [debounceTxn])

  const validateBirthHash = useCallback(
    async (txn: CrossChainTransaction) => {
      const rpc = BASE_RPC_SCAN_URLS[txn.inputChain]
      if (!rpc) return false

      const status = await new ethers.providers.JsonRpcProvider(rpc)
        .getTransactionReceipt(txn.hash)
        .then((receipt) => receipt?.status)
        .catch(() => null)

      if (status === TransactionStatus.Failed) {
        updateTransaction({
          ...txn,
          status: CrossChainStatus.Aborted,
        })
        return false
      }
      return status === TransactionStatus.Success
    },
    [updateTransaction],
  )

  const listener = useCallback(
    async (txn: CrossChainTransaction) => {
      if (!txn || txn.status === CrossChainStatus.Inbound) return

      // https://www.zetachain.com/docs/reference/openapi/

      const inTxHashToCctxData = await getInTxHashToCctxData(txn.hash)
      if (inTxHashToCctxData) {
        let parseData = mapperTxHash(inTxHashToCctxData, txn)

        const inTxHashToCctx = await getInTxHashToCctx(parseData.zetachainHashOb)
        const obHash = inTxHashToCctx?.cctx_index?.[0] || parseData.zetachainHashOb

        if (obHash) {
          const cctxDetails = await getCctxDetails(obHash)

          parseData = mapperTxHash(
            {
              ...cctxDetails,
            },
            txn,
          )
        }

        if (JSON.stringify(parseData) !== JSON.stringify(txn)) {
          updateTransaction(parseData)
        }
      }
    },
    [updateTransaction],
  )

  const callTxn = useCallback(() => {
    debounceTxn.forEach((txn: CrossChainTransaction) => {
      try {
        listener(txn)
      } catch (error) {
        console.error(error)
      }
    })
  }, [listener, debounceTxn])

  const validateHash = useCallback(async () => {
    const isHaveAnyTxnSuccess: boolean[] = await Promise.all(
      debounceTxn.map(async (txn: CrossChainTransaction) => {
        if (!txn || txn.status !== CrossChainStatus.Inbound) return false

        if (isBitcoinChain(txn.inputChain)) {
          updateTransaction({
            ...txn,
            status: CrossChainStatus.PendingInbound,
          })
          return true
        }

        const isAccept = await validateBirthHash(txn)
        if (isAccept) {
          updateTransaction({
            ...txn,
            status: CrossChainStatus.PendingInbound,
          })
        }

        return isAccept
      }),
    )
    if (isHaveAnyTxnSuccess.some((i) => i)) {
      debounce(() => {
        refetchBalances()
      }, 2000)()
    }
  }, [refetchBalances, debounceTxn, updateTransaction, validateBirthHash])

  useEffect(() => {
    let interval = null
    if (debounceTxn.length === 0) {
      clearInterval(interval)
      return
    }

    debounce(callTxn, 2000)()

    interval = setInterval(() => {
      if (debounceTxn.length === 0) {
        clearInterval(interval)
        return
      }
      callTxn()
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [callTxn, debounceTxn])

  useEffect(() => {
    let interval = null

    if (txnInbounds.length === 0) {
      clearInterval(interval)
      return
    }

    interval = setInterval(() => {
      if (txnInbounds.length === 0) {
        clearInterval(interval)
        return
      }
      validateHash()
    }, 5 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [txnInbounds, validateHash])

  return null
}
export default CrossChainTransactionUpdater
