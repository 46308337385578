import { ChainId } from '@abstra-dex/sdk'
import addresses from './addresses.json'

export default {
  masterChef: {
    7000: addresses[7000].farmV2,
    7001: addresses[7001].farmV2,
  },
  masterChefV1: {
    // 7000: addresses[111].WAGFarmV1,
    7001: addresses[7001].WAGFarmV1,
  },
  sousChef: {
    7000: addresses[7000].stakingFactory,
    7001: addresses[7001].stakingFactory,
  },
  lotteryV2: {
    7000: '0x11FbA0b1ED82824b2bccC99a3098e6328ba4C610',
    7001: '0xCc98EA77643713A1bBe42C468e3D8263E635ed29',
  },
  multiCall: {
    7001: addresses[7001].Multicall2,
    7000: addresses[7000].Multicall2,
  },
  launchpad: {
    7001: addresses[7001].Launchpad,
    7000: addresses[7000].Launchpad,
  },
  pancakeProfile: {
    7001: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    7000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    7001: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    7000: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    7001: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    7000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    7001: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    111: '',
  },
  pointCenterIfo: {
    7001: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    111: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    7001: '0xFee8A195570a18461146F401d6033f5ab3380849',
    111: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    7001: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    111: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    7001: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    111: '',
  },
  tradingCompetitionMobox: {
    7001: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    111: '',
  },
  tradingCompetitionMoD: {
    7001: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    111: '',
  },
  easterNft: {
    7001: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    111: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    7001: addresses[7001].stakingLockVault,
    7000: addresses[7000].stakingLockVault,
  },
  predictions: {
    7001: '0x18B2A687610328590Bc8F2e5MantaDe3b582A49cdA',
    111: '',
  },
  chainlinkOracle: {
    7001: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    111: '',
  },
  bunnySpecialCakeVault: {
    7001: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    111: '',
  },
  bunnySpecialPrediction: {
    7001: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    111: '',
  },
  bunnySpecialLottery: {
    7001: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    111: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    7001: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    111: '',
  },
  farmAuction: {
    7001: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    111: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    7001: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    111: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  galaxyNftClaiming: {
    7001: '0x2D18f2d27D50C9b4013DEBA3D54f60996bD8847E',
    111: '',
  },
  nftMarket: {
    7001: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    111: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    7001: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    111: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    7001: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    111: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  uniswapv2Router02: {
    [ChainId.MAINNET]: '0x2ca7d64A7EFE2D62A725E2B35Cf7230D6677FfEe',
    [ChainId.TESTNET]: '0x2ca7d64A7EFE2D62A725E2B35Cf7230D6677FfEe',
  },
  erc20Custody: {
    [ChainId.MAINNET]: '',
    [ChainId.TESTNET]: '',

    [ChainId.BTC_MAINNET]: '',
    [ChainId.BTC_TESTNET]: '',

    [ChainId.BSC_MAINNET]: '0x00000fF8fA992424957F97688015814e707A0115',
    [ChainId.BSC_TESTNET]: '0x0000a7db254145767262c6a81a7ee1650684258e',

    [ChainId.ETH]: '0x0000030Ec64DF25301d8414eE5a29588C4B0dE10',
    [ChainId.GÖRLI]: '0x000047f11c6e42293f433c82473532e869ce4ec5',

    [ChainId.POLYGON]: '',
    [ChainId.MUMBAI]: '0x0000a7db254145767262c6a81a7ee1650684258e',
  },
  omnichain: {
    [ChainId.MAINNET]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.TESTNET]: '0xBCD5f1cBF8f1eD5440da86ABA5F2E76C025E677E',

    [ChainId.BTC_MAINNET]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.BTC_TESTNET]: '0xBCD5f1cBF8f1eD5440da86ABA5F2E76C025E677E',

    [ChainId.BSC_MAINNET]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.BSC_TESTNET]: '0xBCD5f1cBF8f1eD5440da86ABA5F2E76C025E677E',

    [ChainId.ETH]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.GÖRLI]: '0xBCD5f1cBF8f1eD5440da86ABA5F2E76C025E677E',

    [ChainId.POLYGON]: '0x357cded48A21A83CD476bB216605af636f4623a5',
    [ChainId.MUMBAI]: '0xBCD5f1cBF8f1eD5440da86ABA5F2E76C025E677E',
  },
  tss: {
    [ChainId.BTC_MAINNET]: 'bc1qm24wp577nk8aacckv8np465z3dvmu7ry45el6y',
    [ChainId.BTC_TESTNET]: 'tb1qy9pqmk2pd9sv63g27jt8r657wy0d9ueeh0nqur',

    [ChainId.MAINNET]: '0x70e967acFcC17c3941E87562161406d41676FD83',
    [ChainId.TESTNET]: '0x7c125C1d515b8945841b3d5144a060115C58725F',

    [ChainId.BSC_MAINNET]: '0x70e967acFcC17c3941E87562161406d41676FD83',
    [ChainId.BSC_TESTNET]: '0x8531a5aB847ff5B22D855633C25ED1DA3255247e',

    [ChainId.ETH]: '0x70e967acFcC17c3941E87562161406d41676FD83',
    [ChainId.GÖRLI]: '0x8531a5aB847ff5B22D855633C25ED1DA3255247e',

    [ChainId.POLYGON]: '0x70e967acFcC17c3941E87562161406d41676FD83',
    [ChainId.MUMBAI]: '0x8531a5aB847ff5B22D855633C25ED1DA3255247e',
  },
}
