import { Box, Button } from 'packages/uikit'
import styled from 'styled-components'
// import Select, { DropDownContainer, DropDownHeader } from 'components/Select/Select'
import TransactionHistories from 'views/CrossSwap/components/TransactionHistories'
import SelectChain from 'views/CrossSwap/components/SelectChain'
import { motion } from 'framer-motion'

export const Wrapper = styled(Box)`
  padding: 36px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.absModalBg};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 36px;
  }
`

export const SwapSelect = styled(SelectChain)``

export const BalanceWrapper = styled.div`
  transform: translateY(-26px);
`

export const XdefiConnectButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  max-height: 53px;

  background: ${({ theme }) => theme.colors.absBlackColor};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  padding: 8px 12px;
`

export const IconWrapper = styled(motion.div)<{ width: string; height: string; bg: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ theme, bg }) => theme.colors[bg]};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SwapIcon = styled(IconWrapper)`
  margin: 0 auto 16px auto;
`

export const StyledTransactionHistories = styled(TransactionHistories)`
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 99;

  padding: 10px 16px 16px;
  background: ${({ theme }) => theme.colors.dropdownDeep};

  max-width: 370px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    top: 12px;
    right: 42px;
  }
`

export const TransactionWrapper = styled(Box)`
  padding: 8px 16px 8px 8px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
    border-radius: 8px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.absModalBg};
    border-radius: 8px;

    &:hover {
      background: rgba(209, 250, 229, 0.65);
    }
  }
`
