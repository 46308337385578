/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { TokenAmount } from '@abstra-dex/sdk'

export type RequestProps = {
  method: string
  params: any[]
}

export type BasePropsTransfer = {
  from: string
  recipient: string
  tokenAmount: TokenAmount
  memo: string
  feeRate: number
}
export type BitcoinAccountWallet = {
  address: string
  publicKey: string
}

export const FEE_RATE = 10

export class WalletProvider<T> {
  public provider: T

  constructor(args: any) {
    this.provider = args
  }

  protected connect: () => Promise<any> = () => {
    return null
  }

  protected getAccounts = (): Promise<BitcoinAccountWallet[]> => {
    return null
  }

  protected getAccount = (): Promise<BitcoinAccountWallet> => {
    return null
  }

  protected changeNetwork = (...args) => {}

  protected getNetwork = () => {}

  protected request = (data: RequestProps, callback?: (error: any, hash: any) => void) => {}

  protected transfer = (data: BasePropsTransfer): Promise<string> => {
    return null
  }

  protected depositBTC = (...args) => {}

  protected swapBTC = (...args) => {}
}
