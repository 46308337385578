/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, FC, useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { useMatchBreakpoints } from 'packages/uikit/src/hooks'
import { Flex, Button, Text, ChevronDownIcon, Box } from 'packages/uikit'
import MenuItems from 'packages/uikit/src/components/MenuItems'
import { NextLinkFromReactRouter } from 'components/NextLink'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import Select, { OptionProps, DropDownListContainer, DropDownHeader } from 'components/Select/Select'
import { FlexGap } from 'components/Layout/Flex'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
// import Link from 'next/link'
import { AbstradexMainContainer } from './styles'
import UserMenu from '../../Menu/UserMenu'
import { AbstradexButton, Faucet } from '../components/ui'
import SelectChain from './SelectChain'

export const Header = ({ mobileList, activeItem }) => {
  const { isMobile } = useMatchBreakpoints()
  const [isOpen, setIsOpen] = useState(false)
  const [showFaucet, setShowFaucet] = useState(false)
  const router = useRouter()
  const { account, library } = useActiveWeb3React()
  const { t } = useTranslation()

  const [verify, setVerify] = useState({
    isTwitter: false,
    isDiscord: false,
    isTelegram: false,
  })

  const [isVerify, setIsVerify] = useState(false)

  const handleVerifyTwitter = useCallback(() => {
    window.open('https://twitter.com/AbstraDEX', '_blank')
    sleep(200).then(() => {
      setVerify({
        ...verify,
        isTwitter: true,
      })
    })
  }, [verify])

  const handleVerifyDiscord = useCallback(() => {
    window.open('https://discord.gg/YTrtVFkbZe', '_blank')
    sleep(200).then(() => {
      setVerify({
        ...verify,
        isDiscord: true,
      })
    })
  }, [verify])

  const handleVerifyTelegram = useCallback(() => {
    window.open('https://t.me/Abstradex', '_blank')
    sleep(200).then(() => {
      setVerify({
        ...verify,
        isTelegram: true,
      })
    })
  }, [verify])

  useEffect(() => {
    if (verify.isTwitter && verify.isDiscord && verify.isTelegram) {
      setIsVerify(true)
    }
  }, [verify.isDiscord, verify.isTelegram, verify.isTwitter])

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  useEffect(() => {
    if (showFaucet) {
      setShowFaucet(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  const handleOpenMenu = () => setIsOpen((prev) => !prev)

  const handleOpenFaucet = () => setShowFaucet((prev) => !prev)

  return (
    <AbstradexMainHeader>
      <AbstradexMainContainer>
        <Flex>
          {isMobile ? (
            <MenuMobile>
              <FlexGap gap="8px">
                <Button
                  className="menu-button"
                  onClick={handleOpenMenu}
                  style={{
                    filter: 'invert(100%) sepia(100%) saturate(100%) hue-rotate(90deg) brightness(100%) contrast(100%)',
                  }}
                >
                  <img src="/images/abstradex/svgs/menu.svg" alt="mb-menu" />
                </Button>

                <Button className="menu-logo" as={NextLinkFromReactRouter} to="https://abstradex.xyz/">
                  <Flex
                    flexDirection="column"
                    style={{
                      fontSize: '12px',
                      gap: '5px',
                      color: '#fff',
                      fontWeight: '800',
                    }}
                  >
                    <img src="/images/abstradex/svgs/logo.svg" alt="" />
                    AbstraDEX
                  </Flex>
                </Button>
              </FlexGap>

              <MenuList isOpen={isOpen}>
                <MenuMobileList
                  flexDirection="column"
                  items={mobileList}
                  activeItem={activeItem}
                  textColor="absWhiteColor"
                />
                <AbstraV3MobileButton as={NextLinkFromReactRouter} to="https://omnichain.abstradex.xyz/">
                  V3 Cross-chain
                </AbstraV3MobileButton>
                {/* {showFaucet ? (
                  <Faucet
                    account={account}
                    library={library}
                    handleVerifyTwitter={handleVerifyTwitter}
                    handleVerifyDiscord={handleVerifyDiscord}
                    handleVerifyTelegram={handleVerifyTelegram}
                    status={verify}
                    isVerify={isVerify}
                  />
                ) : null} */}
              </MenuList>
            </MenuMobile>
          ) : null}

          <HeaderButtons>
            <Peckshield
              as={NextLinkFromReactRouter}
              to="https://twitter.com/AbstraDEX/status/1763380883300745682"
              alignItems="center"
            >
              <Text fontSize="clamp(12px, 2vw, 16px)" style={{ whiteSpace: 'nowrap' }}>
                Audit by
              </Text>
              <img src="/images/peckshield-icon.svg" alt="shield-icon" />
            </Peckshield>

            <AbstraV3Button as={NextLinkFromReactRouter} to="https://omnichain.abstradex.xyz/">
              V3 Cross-chain
              <img src="/images/abstradex/svgs/crown.svg" alt="menu" />
            </AbstraV3Button>

            {/* <FaucetButton onClick={handleOpenFaucet}>Faucet ZETA</FaucetButton> */}

            <SelectChain />

            <UserMenu />
          </HeaderButtons>
        </Flex>
      </AbstradexMainContainer>

      {/* {!isMobile && showFaucet ? (
        <Faucet
          account={account}
          library={library}
          handleVerifyTwitter={handleVerifyTwitter}
          handleVerifyDiscord={handleVerifyDiscord}
          handleVerifyTelegram={handleVerifyTelegram}
          status={verify}
          isVerify={isVerify}
        />
      ) : null} */}
    </AbstradexMainHeader>
  )
}

const AbstradexMainHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.absMainColor};
  width: 100%;
  min-height: auto;
  padding: 10px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.md} {
    background-color: ${({ theme }) => theme.colors.absTopBottomColor};
    min-height: 80px;
    width: fit-content;
  }
`

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  grid-gap: 12px;
  width: 100%;
`

const MenuMobile = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;

  .menu-button,
  .menu-logo {
    background-color: transparent;
    padding: 0;

    img {
      height: 24px;
    }
  }

  .menu-button {
    img {
      transform: rotate(180deg);
    }
  }
`

const MenuMobileList = styled(MenuItems)`
  white-space: nowrap;

  & > * {
    width: 100%;
  }
`

const MenuList = styled.div<{ isOpen: boolean }>`
  width: 175px;

  position: absolute;
  padding: 10px 0;
  top: 65px;
  left: 0;
  z-index: ${({ isOpen }) => (isOpen ? 99 : -1)};

  height: auto;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.absMainColor};
  transition: opacity 0.25s linear;

  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`

const AbstraV3Button = styled.div`
  min-width: 75px;
  width: fit-content;
  position: relative;
  min-height: 29px;
  border-radius: 8px;
  background-color: #1a1e21;
  color: ${({ theme }) => theme.colors.absWhiteColor};

  display: none;

  padding: 0 16px;
  font-size: 10px;
  cursor: pointer;

  & img {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 193px;
    min-height: 44px !important;
    background-color: rgba(0, 87, 65, 0.61);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
`

const AbstraV3MobileButton = styled(AbstraV3Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 8px 0 8px 32px;
`

const StyledSelectChain = styled(FlexGap)`
  cursor: pointer;
  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    transform: scale(1.07);
  }

  img {
    width: 24px;
  }
`

const sleep = (time) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

const Peckshield = styled(Button)`
  position: absolute;
  bottom: -50%;
  right: 15px;
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 5px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    white-space: nowrap;
  }
`
