import { ChainId } from '@abstra-dex/sdk'

// TODO  ADD CHAIN
export const isBitcoinChain = (chainId: ChainId | ChainId) =>
  [ChainId.BTC_TESTNET, ChainId.BTC_MAINNET].includes(chainId as any)

export const isZetaChain = (chainId: ChainId | ChainId) => [ChainId.TESTNET, ChainId.MAINNET].includes(chainId as any)

export const isTestnet = (chainId: ChainId | ChainId) =>
  [ChainId.TESTNET, ChainId.GÖRLI, ChainId.BSC_TESTNET, ChainId.MUMBAI, ChainId.BTC_TESTNET].includes(
    chainId as unknown as ChainId,
  )

// export const getZetaNetworkName = (chainId: ChainId): ZetaProtocolNetwork => {
//   switch (chainId) {
//     case ChainId.MAINNET:
//       return null // TODO MAINNET

//     // case 1:
//     // return 'eth_mainnet'  // TODO MAINNET

//     case 97:
//       return 'bsc_testnet'
//     case 5:
//       return 'goerli_testnet'
//     case 80001:
//       return 'mumbai_testnet'

//     case ChainId.TESTNET:
//       return 'zeta_testnet'
//     // case ChainId.BTC_MAINNET:
//     //   return 'btc_mainnet' // TODO MAINNET
//     case ChainId.BTC_TESTNET:
//       return 'btc_testnet'

//     default:
//       return null
//   }
// }
