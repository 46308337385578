/* eslint-disable lodash/import-scope */
import { ChainId } from '@abstra-dex/sdk'
import { CHAIN_ID } from 'config/constants/networks'
import { authenticatedBitcoin } from 'contexts/DefiContext'
import { useAuthBitcoinContext, useBitcoinAddress, useBitcoinLogout } from 'contexts/DefiContext/hook'
import { useTranslation } from 'contexts/Localization'
import { useCallback, useEffect, useMemo } from 'react'
import {
  WalletBitcoinConnectorEnums,
  useWalletBitcoinProviders,
} from './WalletBitcoinProvider/useWalletBitcoinProviders'
import useActiveWeb3React from './useActiveWeb3React'
import useDebounce from './useDebounce'
import useToast from './useToast'

export const useAuthDefi = () => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const walletBitcoinProvider = useWalletBitcoinProviders()

  const { updateBitcoinAddress } = useAuthBitcoinContext()

  const { toastError } = useToast()

  const login = useCallback(
    async (wallet: WalletBitcoinConnectorEnums, chainId: ChainId = parseInt(CHAIN_ID, 10)) => {
      const provider = walletBitcoinProvider[wallet]
      try {
        if (provider && account) {
          await provider.connect()
          await provider.changeNetwork(chainId)

          const btc = await provider.getAccount()

          localStorage.setItem(authenticatedBitcoin, wallet)
          updateBitcoinAddress(btc.address)

          return btc
        }

        toastError(t('Bitcoin Connect'), t("You don't have bitcoin wallet"))
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    [account, t, toastError, updateBitcoinAddress, walletBitcoinProvider],
  )

  const switchNetwork = useCallback(
    async (chainId: ChainId) => {
      const wallet = localStorage.getItem(authenticatedBitcoin)
      await login(wallet as WalletBitcoinConnectorEnums, chainId)
    },
    [login],
  )

  return useMemo(() => ({ login, switchNetwork, isLoaded: account }), [login, switchNetwork, account])
}

export const useAutoConnectBitcoinWallet = () => {
  const bitcoinAddress = useBitcoinAddress()
  const logoutBitcoin = useBitcoinLogout()

  const { isLoaded, login } = useAuthDefi()
  const debounceLoad = useDebounce(isLoaded, 500)

  useEffect(() => {
    const wallet = localStorage.getItem(authenticatedBitcoin)
    if (wallet && debounceLoad) {
      login(wallet as WalletBitcoinConnectorEnums)
    } else if (bitcoinAddress && !wallet) {
      logoutBitcoin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceLoad, login])
}
