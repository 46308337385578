import { ChainId, MCurrencyAmount, Native, TokenAmount, mCurrency } from '@abstra-dex/sdk'
import ZRC20 from '@zetachain/protocol-contracts/abi/zevm/ZRC20.sol/ZRC20.json'
import { TokenForeignTypeEnums } from 'config/constants/cross-chain'
import { chainIds } from 'config/constants/networks'
import { getAddressZrc20ByChainId, getForeignCoins, getNativeTokenEVMAtZeta } from 'state/cross-swap/types'
import { multicallv2 } from 'utils/multicall'
import { isZetaChain } from 'utils/network'

export const fetchNativeFees = async (): Promise<{ [key in ChainId]: MCurrencyAmount<mCurrency> }> => {
  const chainIdAccept = chainIds
    .map((chainId) => {
      const address = getAddressZrc20ByChainId(Native.onChain(chainId), chainId)

      if (!address || isZetaChain(chainId)) return null

      return {
        chainId,
        request: { address, name: 'withdrawGasFee' },
      }
    })
    .filter((item) => item)

  const requests = await multicallv2(
    ZRC20.abi,
    chainIdAccept.map((chainId) => chainId.request),
    { requireSuccess: false },
  )

  return requests.reduce((state, [, withdrawGasFee], index) => {
    const chain = chainIdAccept[index].chainId
    const gasFee = withdrawGasFee.toString()
    state[chain] = MCurrencyAmount.fromRawAmount(Native.onChain(chain), gasFee.toString())
    return state
  }, {})
}

export const fetchZrc20Fee = async (): Promise<{ [key in ChainId]: TokenAmount }> => {
  const tokens = getForeignCoins().filter((token) => token.type === TokenForeignTypeEnums.ERC20)
  const tokenAccept = tokens
    .map((token) => {
      if (!token?.zrc20Address) return null

      return {
        token,
        request: {
          address: token.zrc20Address,
          name: 'withdrawGasFee',
        },
      }
    })
    .filter((item) => item)

  const requests = await multicallv2(
    ZRC20.abi,
    tokenAccept.map((token) => token.request),
    { requireSuccess: false },
  )

  return requests.reduce((state, [, withdrawGasFee], index) => {
    const gasFee = withdrawGasFee.toString()
    const chain = tokenAccept[index].token.foreignChainId

    const token = getNativeTokenEVMAtZeta(chain)
    state[chain] = new TokenAmount(token, gasFee.toString())
    return state
  }, {})
}

export const fetchFees = async () => {
  const nativeFees = await fetchNativeFees()
  const zrc20Fee = await fetchZrc20Fee()
  return {
    feesZRC20: zrc20Fee,
    feesZNative: nativeFees,
  }
}
