import { ChainId, TokenAmount } from '@abstra-dex/sdk'
import { WalletAddress, getAddresses } from '@ordzaar/ordit-sdk/unisat'
import { BTC_CHAIN_ID, CHAIN_ID } from 'config/constants/networks'
import { clear0xAddress, getOmnichainAddress, getTssAddress } from 'utils/addressHelpers'
import { isTestnet } from 'utils/network'
import { BasePropsTransfer, BitcoinAccountWallet, FEE_RATE, RequestProps, WalletProvider } from './base'

enum BitcoinNetworkEnum {
  BITCOIN_MAINNET = 'livenet',
  BITCOIN_MAINNET_APP = 'mainnet',
  BITCOIN_TESTNET = 'testnet',
}

type IWalletProvider = {
  call: (...args) => void
  callBacksMap: any
  chainId: ChainId
  connect: (...args) => void
  getAccounts: () => Promise<string[]>
  requestAccounts: () => Promise<void>
  getPublicKey: () => Promise<string>
  getNetwork: () => Promise<string>

  sendBitcoin: () => Promise<any>

  _request: (args: RequestProps, callback?: (error: any, hash: any) => void) => any
  switchNetwork: (network: BitcoinNetworkEnum) => void
  signMessage: (...args) => any
}

export class UniSatWalletProviderProvider extends WalletProvider<IWalletProvider> {
  public connect = async () => {
    return this.provider.requestAccounts()
  }

  public getAccounts = async (): Promise<BitcoinAccountWallet[]> => {
    const accounts = await getAddresses(
      isTestnet(parseInt(CHAIN_ID, 10)) ? BitcoinNetworkEnum.BITCOIN_TESTNET : BitcoinNetworkEnum.BITCOIN_MAINNET_APP,
    )

    return accounts.map(
      (account: WalletAddress): BitcoinAccountWallet => ({
        address: account.address,
        publicKey: account.publicKey,
      }),
    )
  }

  public getAccount = async (): Promise<BitcoinAccountWallet> => {
    const account = (await this.getAccounts())?.[0]
    const key = await this.provider.getPublicKey()

    return {
      ...account,
      publicKey: key,
    }
  }

  public getNetwork = async (): Promise<ChainId> => {
    const network = await this.provider.getNetwork()
    return network === BitcoinNetworkEnum.BITCOIN_MAINNET ? ChainId.BTC_MAINNET : ChainId.BTC_TESTNET
  }

  public changeNetwork = async (chainId: ChainId) => {
    return this.provider.switchNetwork(
      isTestnet(chainId) ? BitcoinNetworkEnum.BITCOIN_TESTNET : BitcoinNetworkEnum.BITCOIN_MAINNET,
    )
  }

  public request = (data: RequestProps, callback?: (error: any, hash: any) => void) => {
    return this.provider._request(data, callback)
  }

  public transfer = async ({ feeRate, from, recipient, tokenAmount, memo }: BasePropsTransfer): Promise<string> => {
    const amount = parseInt(tokenAmount.raw.toString(), 10)
    const accounts = await this.getAccounts()
    const account = accounts.find((a) => a.address === from)
    const network = await this.getNetwork()
    const parseNetwork =
      network === ChainId.BTC_MAINNET ? BitcoinNetworkEnum.BITCOIN_MAINNET_APP : BitcoinNetworkEnum.BITCOIN_TESTNET

    return null
    // const psbt = new PSBTBuilder({
    //   address: account.address,
    //   publicKey: account.publicKey,
    //   feeRate,
    //   network: parseNetwork,
    //   outputs: [
    //     {
    //       address: recipient,
    //       value: Number(amount),
    //     },
    //   ],
    // })
    // await psbt.prepare()

    // const data = await signPsbt(psbt.toPSBT())
    // const OP_RETURN = Utils.compileMemo(memo)
    // const psbt = new Bitcoin.Psbt({ network: Bitcoin.networks.testnet }) // Network-specific
    // psbt.addOutput({ address: from, value: amount }) // Add output {address, value}

    // psbt.addOutput({ script: OP_RETURN, value: 0 }) // Add OP_RETURN {script, value}

    // psbt.signAllInputs(btcKeys) // Sign all inputs
    // psbt.finalizeAllInputs() // Finalise inputs
    // const txHex = psbt.extractTransaction().toHex() // TX extracted and formatted to hex
    // const psbtBase64 = psbt.toBase64()

    // // Ký giao dịch
    // const signedTx = await this.provider.signTransaction({ psbt: psbtBase64 })

    // return new Promise((resolve, reject) =>
    //   getTxIdByHex(parseNetwork, txHex)
    //     .then((txId) => {
    //       resolve(txId)
    //     })
    //     .catch((error) => {
    //       reject(error)
    //     }),
    // )
  }

  public depositBTC = ({
    evmAddress,
    from,
    tokenAmount,
  }: {
    from: string
    evmAddress: string
    tokenAmount: TokenAmount
  }) => {
    const memo = `hex::${clear0xAddress(evmAddress)}`

    return this.transfer({
      from,
      recipient: getTssAddress(BTC_CHAIN_ID),
      feeRate: FEE_RATE,
      memo,
      tokenAmount,
    })
  }

  public swapBTC = ({
    evmAddress,
    from,
    inputTokenAmount,
    outputTokenAmount,
    action,
  }: {
    from: string
    evmAddress: string
    inputTokenAmount: TokenAmount
    outputTokenAmount: TokenAmount
    action: '01' | '02'
  }) => {
    const contract = clear0xAddress(getOmnichainAddress(inputTokenAmount.token.chainId as any))
    const zrc20 = clear0xAddress(outputTokenAmount.token.address)
    const dest = clear0xAddress(evmAddress)

    const memo = `hex::${contract}${action}${zrc20}${dest}`

    return this.transfer({
      from,
      recipient: getTssAddress(BTC_CHAIN_ID),
      feeRate: FEE_RATE,
      memo,
      tokenAmount: inputTokenAmount,
    })
  }
}
