import { ChainId, Currency, Token } from '@abstra-dex/sdk'
import { MethodCrossSwap } from 'contexts/DefiContext'

// - Inbound: create tx, waiting confirm at explorer
// - PendingInbound: some observer sees inbound tx
// - PendingOutbound: super majority observer see inbound tx
// - OutboundMined: the corresponding outbound tx is mined
// - PendingRevert: outbound cannot succeed; should revert inbound
// - Reverted: inbound reverted.
// - Aborted: inbound tx error or invalid paramters and cannot revert; just abortstring
export enum CrossChainStatus {
  Inbound = 'Inbound',
  PendingInbound = 'PendingInbound',
  PendingOutbound = 'PendingOutbound',

  OutboundMined = 'OutboundMined',
  PendingRevert = 'PendingRevert',
  Reverted = 'Reverted',
  Aborted = 'Aborted',
}

type CrossChainTransactionBase = {
  method: MethodCrossSwap
  hash: string

  sourceChainHash?: string
  sourceChainId?: ChainId

  zetachainHashOb?: string
  destinationHash?: string
  destinationChain?: ChainId

  createTime?: number
  updateTime?: number
  status?: CrossChainStatus
}

export type TransactionCrossChainSwap = CrossChainTransactionBase & {
  method:
    | MethodCrossSwap.withdrawBTC
    | MethodCrossSwap.withdrawZRC20
    | MethodCrossSwap.depositNative
    | MethodCrossSwap.depositBTC
    | MethodCrossSwap.crossChainSwapBTC
    | MethodCrossSwap.crossChainSwapBTCTransfer
    | MethodCrossSwap.crossChainSwapBTCTransferNative
    | MethodCrossSwap.crossChainSwap
    | MethodCrossSwap.crossChainSwapTransfer
    | MethodCrossSwap.crossChainZeta
    | MethodCrossSwap.crossChainSwapNative
    | MethodCrossSwap.crossChainSwapNativeTransfer
    | MethodCrossSwap.withdrawERC20
    | MethodCrossSwap.depositERC20

  amountIn: string
  amountOut: string
  inputChain: ChainId
  outputChain: ChainId
  inputCurrency: Token | Currency
  outputCurency: Token | Currency
}

export type CrossChainTransaction = TransactionCrossChainSwap
