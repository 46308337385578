import { createAction } from '@reduxjs/toolkit'
import { CrossChainTransaction } from './types'

export const addStateTransactions = createAction<{ account: string; txn: CrossChainTransaction }>(
  'cross-chain-transactions/addStateTransactions',
)
export const updateStateTransaction = createAction<{ account: string; txn: CrossChainTransaction }>(
  'cross-chain-transactions/updateStateTransaction',
)

export const removeStateTransactions = createAction<{ account: string; txn: CrossChainTransaction }>(
  'cross-chain-transactions/removeStateTransactions',
)
