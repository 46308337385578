/* eslint-disable lodash/import-scope */
import { Modal, ModalBody, useModalContext, ModalContainer, ModalHeader, ModalTitle, Heading } from 'packages/uikit'
import styled from 'styled-components'
import { useCrossChainTransaction } from 'state/cross-swap-transactions/hooks'
import CrossChainTransaction from '../CrossChainTransaction'

const ModalTransactionHistory = () => {
  const { onDismiss } = useModalContext()
  const transactions = useCrossChainTransaction()

  return (
    <StyleModal title="Transaction History" onDismiss={onDismiss} maxWidth="432px">
      {transactions.map((txn) => {
        return <CrossChainTransaction transaction={txn} key={txn.hash} />
      })}
    </StyleModal>
  )
}

export default ModalTransactionHistory

const StyleModal = styled(Modal)`
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.dropdownDeep};

  ${ModalBody} {
    gap: 0;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
      border-radius: 8px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.absModalBg};
      border-radius: 8px;

      &:hover {
        background: rgba(209, 250, 229, 0.65);
      }
    }
  }

  ${ModalContainer} {
    max-width: 100%;
  }

  ${ModalHeader} {
    ${ModalTitle} {
      ${Heading} {
        color: ${({ theme }) => theme.colors.text};
      }
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`
