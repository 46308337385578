export enum CoinType {
  ERC20 = 'ERC20',
  ZRC20 = 'ZRC20',
  GAS = 'Gas',
}
export const authenticatedBitcoin = 'bitcoin-authenticated'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const MINIMUM_ZETA_TO_BTC = 10 // 10 zeta
export const MINIMUM_STATE_ZC20_TO_ERC20 = 5 // 5$;
export const MINIMUM_ERC20_TO_NATIVE = 10 // 10$;

export enum TokenForeignTypeEnums {
  Gas = 'Gas',
  ERC20 = 'ERC20',
}

export enum MethodCrossSwap {
  depositERC20 = 'depositERC20',
  withdrawERC20 = 'withdrawERC20',

  crossChainZeta = 'crossChainZeta',
  depositNative = 'depositNative',

  withdrawZRC20 = 'withdrawZRC20',
  crossChainSwap = 'crossChainSwap',

  crossChainSwapTransfer = 'crossChainSwapTransfer',
  crossChainSwapNative = 'crossChainSwapNative',
  crossChainSwapNativeTransfer = 'crossChainSwapNativeTransfer',

  crossChainSwapBTC = 'crossChainSwapBTC',
  crossChainSwapBTCTransfer = 'crossChainSwapBTCTransfer',
  crossChainSwapBTCTransferNative = 'crossChainSwapBTCTransferNative',

  depositBTC = 'depositBTC',
  withdrawBTC = 'withdrawBTC',
}

export enum ActionCrossChainSwapBTC {
  crossChainSwapBTC = '01',
  crossChainSwapBTCTransfer = '02',
  crossChainSwapBTCTransferNative = '03',
}

export enum ActionCrossChainSwap {
  crossChainSwap = '01',
  crossChainSwapTransfer = '02',
  crossChainSwapNativeTransfer = '03',
}
