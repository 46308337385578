import { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Box, BoxProps, Text, ChevronDownIcon } from 'packages/uikit'
import { SelectChainItem } from 'components/abstradex/layout/SelectChain'

export const DropDownHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px 0 16px;
  background: transparent;
  position: relative;
  z-index: 7;

  border-radius: 25px;
  transition: border-radius 0.15s;

  > ${Text} {
    font-weight: 500;
  }
`

export const DropDownListContainer = styled.div`
  height: 0;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scaleY(0) translateX(0);
  z-index: 10;

  overflow: hidden;
  background: rgba(34, 36, 38, 0.66);
  transition: transform 0.15s, opacity 0.15s;

  transform-origin: top;
  width: 100%;
  min-width: 168px;
  border-radius: 8px;

  ${({ theme }) => theme.mediaQueries.md} {
    top: 0;
    transform: scaleY(0) translateX(100%);
  }
`

export const DropDownContainer = styled(Box)<{ isOpen: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 25px;
  height: 40px;
  user-select: none;

  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1) translateX(0);
        opacity: 1;
        border: 1px solid ${({ theme }) => theme.colors.text};

        ${({ theme }) => theme.mediaQueries.md} {
          transform: scaleY(1) translateX(100%);
        }
      }
    `}

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 9;
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.absMainColor};
  }
`

export const PlaceholderTextColor = styled(Text)`
  color: #999999 !important;
`

export const FixedBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 9;
`

export interface SelectProps extends BoxProps {
  options: OptionProps[]

  onOptionChange: (option: OptionProps) => void
  placeHolderText?: string
  selectedOptionIndex: number
  color?: string
  listLabel?: string
}

export interface OptionProps {
  label: string | any
  value: any
  key?: any
}

const SelectChain: React.FunctionComponent<SelectProps> = ({
  options,
  onOptionChange,
  selectedOptionIndex = 0,
  placeHolderText,
  color = 'absBlackColor',
  listLabel,
  ...props
}) => {
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelected, setOptionSelected] = useState(false)

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(!isOpen)
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setIsOpen(false)
    setOptionSelected(true)

    if (onOptionChange) {
      onOptionChange(options[selectedIndex])
    }
  }

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <>
      <DropDownContainer isOpen={isOpen} {...props}>
        {isOpen ? <FixedBackground /> : null}
        <DropDownHeader onClick={toggling}>
          {!optionSelected && placeHolderText ? (
            <PlaceholderTextColor>{placeHolderText}</PlaceholderTextColor>
          ) : (
            <Text color={color}>
              <SelectChainItem
                label={options[selectedOptionIndex]?.label}
                icon={options[selectedOptionIndex]?.icon}
                textColor="absBlackColor"
              />
            </Text>
          )}
        </DropDownHeader>
        <ChevronDownIcon color={color} onClick={toggling} />
        <DropDownListContainer>
          {listLabel ? (
            <Text fontSize="12px" mt="5px" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {listLabel}
            </Text>
          ) : null}

          <DropDownList ref={dropdownRef}>
            {options?.map((option, index) => (
              <ListItem onClick={onOptionClicked(index)} key={option?.key || option.label}>
                <Text color={color}>
                  <SelectChainItem label={option?.label} icon={option?.icon} />
                </Text>
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      </DropDownContainer>
    </>
  )
}

export default SelectChain
